import {
  DEFAULT_INPUT_VALUE_FOR_CALCULATOR,
  FOOTPRINT_CALCULATOR_BASE_ROUTE_ID,
  FOOTPRINT_COMPENSATION_CALCULATOR_STRING
} from '~/constants'
import { getCalculatorRouteObject } from '~/helpers/routes-helper'

export var FootprintCalculatorMixin = {
  computed: {
    currentCalculatorRouteObject() {
      return getCalculatorRouteObject(this.$router.currentRoute.path, this.languageCode, this.$store)
    },
    currentFootprintCalculatorStep() {
      const baseFootprintCalculatorPath = this.currentCalculatorRouteObject.path.split('/').slice(0, 6).join('/')
      return this.$store.getters['store__app__footprint__calculator/getFootprintCalculatorStepsAccordingToBasePath'](
        baseFootprintCalculatorPath
      )
    },
    isCurrentFootprintCalculatorStepAlreadyFilled() {
      return !!this.currentFootprintCalculatorStep?.inputValue
    },
    isFootprintCalculatorPage() {
      return this.currentCalculatorRouteObject?.props?.routeConfig?.isFootprintCalculatorPage
    },
    isCurrentFootprintCalculatorStepBasePath() {
      return this.nextEmptyFootprintCalculatorStep && this.isFootprintRootPage
    },
    isLastFootprintCalculatorStep() {
      return this.$store.getters['store__app__footprint__calculator/isLastFootprintCalculatorStep']
    },
    isFootprintRootPage() {
      return this.currentRouteFromStore?.name?.includes(`event_types-${FOOTPRINT_CALCULATOR_BASE_ROUTE_ID}`)
    },
    nextEmptyFootprintCalculatorStep() {
      return this.$store.getters['store__app__footprint__calculator/getNextEmptyFootprintCalculatorStep']
        ?.eventTypeRootRoute
    },
    nextEmptyFootprintCalculatorStepPath() {
      return this.nextEmptyFootprintCalculatorStep?.path
    }
  },
  methods: {
    footprintCalculatorHook() {
      if (this.isCurrentFootprintCalculatorStepAlreadyFilled) {
        return this.pushToNextFootprintCalculatorPage()
      }
      if (!this.nextEmptyFootprintCalculatorStep) {
        return this.pushToFootprintCalculatorProjectSelectionPath()
      }
      if (this.isCurrentFootprintCalculatorStepBasePath) {
        return this.$router.push(this.nextEmptyFootprintCalculatorStepPath)
      }
    },
    pushToFootprintCalculatorProjectSelectionPath() {
      let calculation_input
      if (this.item) {
        calculation_input = this.item
      } else {
        calculation_input = {
          __typename: 'calculator_input',
          id: this.$store.getters['store__app__footprint__calculator/getLastFilledFootprintCalculatorStep'].inputTypeId
        }
      }

      this.$router.push(
        this.projectSelectionLocation(calculation_input, null, FOOTPRINT_COMPENSATION_CALCULATOR_STRING)
      )
    },
    pushToNextFootprintCalculatorPage() {
      this.$router.push(this.nextEmptyFootprintCalculatorStepPath)
    },
    submitFootprintCalculatorForm() {
      if (this.isFootprintCalculatorPage) {
        this.$store.dispatch('store__app__footprint__calculator/setFootprintCalculatorStepInput', {
          route: this.currentCalculatorRouteObject,
          item: this.item,
          inputValue: this.inputValue || DEFAULT_INPUT_VALUE_FOR_CALCULATOR
        })
        if (this.nextEmptyFootprintCalculatorStep) {
          this.$router.push(this.nextEmptyFootprintCalculatorStepPath)
        } else {
          this.pushToFootprintCalculatorProjectSelectionPath()
        }
        return true
      } else {
        return false
      }
    }
  }
}
