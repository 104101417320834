
export default {
  name: 'AppStatusBoxItem',
  data: function () {
    return {
      hideStatusBox: undefined,
      currentTimeoutID: undefined,
      playHideCounterTimeoutID: undefined,
      pauseHideCounterTimeoutID: undefined
    }
  },
  computed: {
    currentDurationPercent() {
      return (
        (this.statusItem.displayDuration /
          this.$store.getters['store__app/getAppStatusMessagesDefaultDisplayDuration']) *
        100
      )
    },
    currentTranslation() {
      return this.$t(`appStatus.${this.statusItem.type}.headline`)
    },
    getAppStatusItemType() {
      return {
        iconName: `icon-${this.statusItem.type}`,
        class: `app__status-box__item--${this.statusItem.type}`
      }
    }
  },
  props: {
    statusItem: {
      type: Object,
      required: true
    },
    statusItemKey: {
      type: String
    }
  },
  methods: {
    playHideCounter() {
      this.playHideCounterTimeoutID && clearTimeout(this.playHideCounterTimeoutID)
      this.playHideCounterTimeoutID = setTimeout(
        function (thisVue) {
          thisVue.$store.dispatch('store__app/startAppStatusMessagesDuration', thisVue.statusItemKey)
        },
        250,
        this
      )
    },
    pauseHideCounter() {
      this.pauseHideCounterTimeoutID && clearTimeout(this.pauseHideCounterTimeoutID)
      this.pauseHideCounterTimeoutID = setTimeout(
        function (thisVue) {
          thisVue.$store.dispatch('store__app/pauseAppStatusMessagesDuration', thisVue.statusItemKey)
        },
        250,
        this
      )
    },
    closeStatusBox() {
      this.hideStatusBox = 'app__status-box__item--hide'

      this.currentTimeoutID && clearTimeout(this.currentTimeoutID)
      this.currentTimeoutID = setTimeout(
        function (thisVue) {
          thisVue.$store.dispatch('store__app/removeAppStatusMessages', thisVue.statusItemKey)
        },
        500,
        this
      )
      // On change of this 500ms duration, we also have to change the animation duration for app__status-box.scss animation-duration.
    }
  }
}
