import gql from 'graphql-tag'

import { translationsFilter } from '../filters'

export function orderablePrivateProjectsQuery() {
  const query = `
    query orderablePrivateProjects ($locale: String!) {
      projects(
        limit: -1,
        sort: "sort",
        filter: {
          status: { _eq: "published" },
          target_channel: { _eq: "website" },
          target_customer_type: { _eq: "private" }
        }
      ) {
        ${projectFragment()}
      }
    }
  `
  return gql(query)
}

export function orderableBusinessProjectsQuery() {
  const query = `
    query orderableBusinessProjects ($locale: String!) {
      projects(
        limit: -1,
        sort: "sort",
        filter: {
          status: { _eq: "published" },
          target_channel: { _eq: "website" },
          target_customer_type: { _eq: "business" }
        }
      ) {
        ${projectFragment()}
      }
    }
  `
  return gql(query)
}

export function projectQuery() {
  const query = `
    query projectById ($locale: String!, $id: ID!) {
      projects_by_id(id: $id) {
        ${projectFragment()}
      }
    }
  `
  return gql(query)
}

function projectFragment() {
  return `
    id
    price_per_t
    ${translationsFilter()} {
      name
      short_teaser
      languages_code {
        code
      }
    }
    image {
      id
      title
      filename_disk
    }
    page {
      id
    }
  `
}
