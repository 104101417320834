export default {
  getLocalizedPath: (_store, _getters) => (name, params, context) => {
    const location = context.localeLocation({
      name: name,
      params: params
    })

    if (!location) throw new Error(`Can not resolve route with name ${name} according to path ${path}`)

    const resolve = (context.$router || context.app.router).resolve(location)

    return resolve.route.path
  }
}
