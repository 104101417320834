import {
  extractFromCookie,
  removeKeyFromCookie,
  sanitizeCookieFromServer,
  setFootprintCalculatorCookie
} from '~/helpers/cookie-helper.js'

import { languageCodeByPath } from '../helpers/translation-helper'

export const state = () => ({
  footprintCalculatorSteps: [],
  footprintCalculatorLanguage: 'de'
})

export const mutations = {
  mutateFootprintCalculatorLanguage(state, data) {
    state.footprintCalculatorLanguage = data
  },
  mutateClearFootprintInput(state) {
    state.footprintCalculatorSteps.forEach(footprintCalculatorStep => {
      footprintCalculatorStep.co2Factor = null
      footprintCalculatorStep.inputValue = null
      footprintCalculatorStep.inputTypeId = null
      return footprintCalculatorStep
    })
  },
  mutateInsertFootPrintCalculatorStep(state, data) {
    state.footprintCalculatorSteps.push(data)
  },
  mutateFootprintCalculatorStep(state, data) {
    const currentFootprintCalculatorStep = getters['getNextEmptyFootprintCalculatorStep'](state, getters)
    const currentFootprintCalculatorStepIndex = state.footprintCalculatorSteps.findIndex(
      footprintCalculatorStep => footprintCalculatorStep === currentFootprintCalculatorStep
    )

    if (state.footprintCalculatorSteps[currentFootprintCalculatorStepIndex]) {
      state.footprintCalculatorSteps[currentFootprintCalculatorStepIndex].inputValue = data.inputValue
      state.footprintCalculatorSteps[currentFootprintCalculatorStepIndex].co2Factor = data.factor
      state.footprintCalculatorSteps[currentFootprintCalculatorStepIndex].inputTypeId = data.inputTypeId
    }
  }
}

export const actions = {
  clearFootprintInput(vuexContext) {
    vuexContext.commit('mutateClearFootprintInput')
    removeKeyFromCookie('footprintCalculatorSteps')
    removeKeyFromCookie('footprintCalculatorStepsExpirationDate')
  },
  initFootprintCalculatorStepsFromCookie(vuexContext, req) {
    const footprintCalculatorStepsExpirationDate = Number.parseInt(
      extractFromCookie(req, 'footprintCalculatorStepsExpirationDate')
    )

    const cookieFootprintCalculatorSteps = sanitizeCookieFromServer(extractFromCookie(req, 'footprintCalculatorSteps'))

    if (!cookieFootprintCalculatorSteps) return

    if (new Date().getTime() > footprintCalculatorStepsExpirationDate) {
      vuexContext.dispatch('clearFootprintInput')
      return
    }

    const filledFootprintCalculatorSteps = cookieFootprintCalculatorSteps.filter(cookieFootprintCalculatorStep => {
      return cookieFootprintCalculatorStep.inputValue !== null
    })

    filledFootprintCalculatorSteps.forEach(filledFootprintCalculatorStep => {
      vuexContext.getters.getFootprintCalculatorSteps.find(footprintCalculatorStep => {
        if (footprintCalculatorStep.eventTypeRootRoute.name === filledFootprintCalculatorStep.name) {
          footprintCalculatorStep.co2Factor = filledFootprintCalculatorStep.co2Factor
          footprintCalculatorStep.inputValue = filledFootprintCalculatorStep.inputValue
          footprintCalculatorStep.inputTypeId = filledFootprintCalculatorStep.inputTypeId
        }
      })
    })
  },
  setFootprintCalculatorLanguage(vuexContext, data) {
    vuexContext.commit('mutateFootprintCalculatorLanguage', data)
  },
  setFootprintCalculatorStep(vuexContext, route) {
    const footprintCalculatorStep = {
      eventTypeRootRoute: {
        name: route.name,
        path: route.path,
        routeConfig: route.props.routeConfig
      },
      co2Factor: null,
      inputValue: null,
      inputTypeId: null,
      languageCode: languageCodeByPath(route.path)
    }

    vuexContext.commit('mutateInsertFootPrintCalculatorStep', footprintCalculatorStep)
  },
  async setFootprintCalculatorStepInput(vuexContext, data) {
    const { inputValue, item } = data
    // first is calculator_input, second is from event type without explicit input
    const factor = item.factor || item?.calculator_input[0]?.factor
    const inputTypeId = item.id

    await vuexContext.commit('mutateFootprintCalculatorStep', {
      inputValue: inputValue,
      factor: factor,
      inputTypeId: inputTypeId
    })

    const minutesToExpireFootprintCalculatorStepCookie = 60
    const expirationInMilliseconds = minutesToExpireFootprintCalculatorStepCookie * 60000
    const footprintCalculatorStepsExpirationDate = new Date().getTime() + Number.parseInt(expirationInMilliseconds)

    setFootprintCalculatorCookie(
      vuexContext.getters.getFootprintCalculatorSteps,
      footprintCalculatorStepsExpirationDate
    )
  }
}

export const getters = {
  calculatedCo2Amount(_state, getters) {
    const ZERO_CO2_FACTOR_IF_NO_CALCULATOR_STEP_DEFINED = 0
    let co2Amount = 0

    getters.getFootprintCalculatorStepsAccordingToLanguageCode.forEach(footprintCalculatorStep => {
      const co2Factor = footprintCalculatorStep.co2Factor || ZERO_CO2_FACTOR_IF_NO_CALCULATOR_STEP_DEFINED
      co2Amount += co2Factor * footprintCalculatorStep.inputValue
    })

    return co2Amount
  },
  getFootprintCalculatorLanguage(state) {
    return state.footprintCalculatorLanguage
  },
  getFootprintCalculatorSteps(state) {
    return state.footprintCalculatorSteps
  },
  getFootprintCalculatorStepsAccordingToLanguageCode(state) {
    return state.footprintCalculatorSteps.filter(
      footprintCalculatorStep => footprintCalculatorStep.languageCode === state.footprintCalculatorLanguage
    )
  },
  getFootprintCalculatorStepsAccordingToBasePath: state => path => {
    return state.footprintCalculatorSteps.find(
      footprintCalculatorStep => footprintCalculatorStep.eventTypeRootRoute.path === path
    )
  },
  getLastFilledFootprintCalculatorStep(_state, getters) {
    return getters.getFootprintCalculatorStepsAccordingToLanguageCode
      .filter(footprintCalculatorStep => footprintCalculatorStep.inputValue !== null)
      .slice(-1)[0]
  },
  getNextEmptyFootprintCalculatorStep(state, getters) {
    let footprintCalculatorSteps = getters.getFootprintCalculatorStepsAccordingToLanguageCode
    // FIXME: sometimes its a function and sometimes its an array
    // dont know why yet we need typescript!
    if (footprintCalculatorSteps instanceof Function) {
      footprintCalculatorSteps = footprintCalculatorSteps(state)
    }

    if (footprintCalculatorSteps.length > 0) {
      return footprintCalculatorSteps.find(footprintCalculatorStep => footprintCalculatorStep.co2Factor === null)
    }
  },
  isLastFootprintCalculatorStep(state) {
    const unsolvedSteps = state.footprintCalculatorSteps.filter(
      footprintCalculatorStep => footprintCalculatorStep.inputValue === null
    ).length

    return unsolvedSteps === 1
  }
}
