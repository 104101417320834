import GoogleTagManagerBodyScript from '~/plugins/googleTagManager/bodyScript'

export var GoogleTagManagerIntegrationMixin = {
  name: 'GoogleTagManagerIntegrationMixin',
  methods: {
    addGtmScriptToBody() {
      const nuxtContext = this.$nuxt
      GoogleTagManagerBodyScript(nuxtContext)
    }
  },
  mounted() {
    this.addGtmScriptToBody()
  }
}
