import { createOrderMutation } from '~/plugins/graphql'

const logClass = '[StoreApiOrderCreate]'

export const state = () => ({
  errorMessages: [],
  order: {
    trackingId: undefined
  },
  pending: false,
  success: undefined
})

export const mutations = {
  mutateAddErrorMessage(state, data) {
    state.errorMessages.push(data)
  },
  mutateOrder(state, data) {
    state.order = data
  },
  mutatePending(state, data) {
    state.success = undefined
    state.pending = data
  },
  mutateSuccess(state, data) {
    state.pending = false
    state.success = data
  }
}

export const actions = {
  async createOrder(vuexContext, data) {
    vuexContext.commit('mutatePending', true)

    const store = this.app.store
    const isLoggedIn = store.getters['store__app__user/isLoggedIn']
    const user = store.getters['store__app__user/getUser']

    let customer
    if (isLoggedIn && user) {
      customer = store.getters['store__app__customer/getCustomer']
    }

    const orderInput = aggregateOrderData(store, data.order, customer)
    const addressInput = aggregateOrderAddress(store, user.email, customer)
    triggerMutation(orderInput, addressInput, this.app.apolloProvider.defaultClient, vuexContext, this)
  }
}

export const getters = {
  getErrorMessages(state) {
    return state.errorMessages
  },
  getOrder(state) {
    return state.order
  },
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}

// private methods

const aggregateOrderData = (store, order, customer) => {
  let label
  if (store.getters['store__app__order-create/getInputValue']('hasCustomTitle')) {
    label = store.getters['store__app__order-create/getInputValue']('customTitle')
  }
  if (!label || label.length < 1) {
    label = store.getters['store__app__order-create/getDefaultLabel']
  }
  let subject
  if (store.getters['store__app__order-create/getInputValue']('isGiftCertificate')) {
    subject = store.getters['store__app__order-create/getInputValue']('compensationSubjectTitle')
  }
  if (!subject || subject.length < 1) {
    subject = store.getters['store__app__order-create/getDefaultSubject']
  }

  return {
    projectId: order.projectId,
    co2Amount: order.co2Amount,
    price: order.price,
    label: label,
    subject: subject,
    customerId: customer?.id,
    paymentInformations: order.paymentInformations
  }
}

const aggregateOrderAddress = (store, email, customer) => {
  if (customer) {
    if (customer.address_id) {
      return {
        email: email,
        firstname: customer.address_id.firstname,
        lastname: customer.address_id.lastname,
        street: customer.address_id.street,
        housenumber: customer.address_id.housenumber,
        zipcode: customer.address_id.zipcode,
        city: customer.address_id.city
      }
    } else {
      new Error('missing customer address')
    }
  } else {
    return {
      email: store.getters['store__app__order-create/getInputValue']('email'),
      firstname: store.getters['store__app__order-create/getInputValue']('firstname'),
      lastname: store.getters['store__app__order-create/getInputValue']('lastname'),
      street: store.getters['store__app__order-create/getInputValue']('street'),
      housenumber: store.getters['store__app__order-create/getInputValue']('housenumber'),
      zipcode: store.getters['store__app__order-create/getInputValue']('zipcode'),
      city: store.getters['store__app__order-create/getInputValue']('city')
    }
  }
}

const triggerMutation = (orderInput, addressInput, apolloClient, vuexContext, thisBinding) => {
  apolloClient
    .mutate({
      mutation: createOrderMutation,
      variables: {
        orderInput: orderInput,
        addressInput: addressInput
      }
    })
    .then(async response => {
      const errors = response.data.errors
      if (errors) {
        errors.forEach(error => vuexContext.commit('mutateAddErrorMessage', error.message))
        vuexContext.commit('mutateSuccess', false)
      } else {
        vuexContext.commit('mutateOrder', { trackingId: response?.data?.createOrder?.order?.trackingId })
        thisBinding.app.store.dispatch('store__app__footprint__calculator/clearFootprintInput')
        vuexContext.commit('mutateSuccess', true)
      }
    })
    .catch(async error => {
      let errorMessage
      if (error && error.message && error.message.includes('send_order')) {
        errorMessage = error.message
      } else {
        console.warn(logClass, '[triggerMutation]', 'error:', error)
        errorMessage = 'sendOrderFailedGeneric'
        thisBinding.$sentry.captureException(error)
      }
      vuexContext.commit('mutateAddErrorMessage', errorMessage)
      vuexContext.commit('mutateSuccess', false)
    })
}
