import { isLanguageRootPath } from '~/helpers/routes-helper'
import { languageCodeByPath } from '~/helpers/translation-helper'

export default function (context) {
  {
    if (context.$device.isDesktopOrTablet || context.$device.isCrawler) {
      context.store.dispatch('store__app/setCurrentLayoutForDevice', 'default')
      return
    }

    const route = context.route
    const languageCode = languageCodeByPath(route.path)

    if (isLanguageRootPath(route.path)) {
      context.store.dispatch('store__app/setCurrentLayoutForDevice', 'progressiveWebApp')
      return
    }

    if (!context.store.getters['store__app__routes/isRouteAllowedForMobile'](route, context)) {
      return context.redirect({ name: `index___${languageCode}` })
    }

    context.store.dispatch('store__app/setCurrentLayoutForDevice', 'default')
  }
}
