import Vue from 'vue'

import { calculatorRoutes, isCalculatorRootRoute, route } from '~/helpers/routes-helper'

const logClass = '[StoreAppOrderCreate]'

export const state = () => ({})

export const mutations = {
  mutateState(state, data) {
    Vue.set(state, data.key, data.value)
  },
  mutateDefaultLabel(state, label) {
    state.defaultLabel = label
  },
  mutateDefaultSubject(state, subject) {
    state.defaultSubject = subject
  }
}

export const actions = {
  setInputValue(vuexContext, data) {
    const logMethod = '[setInputValue]'
    const valid_data = [
      'co2Amount',
      'label',
      'price',
      'project',
      'subject',
      'firstname',
      'lastname',
      'street',
      'housenumber',
      'zipcode',
      'city',
      'email',
      'authType',
      'isGiftCertificate',
      'compensationSubjectTitle',
      'hasCustomTitle',
      'customTitle',
      'paymentInformations'
    ]
    if (valid_data.includes(data.key)) {
      vuexContext.commit('mutateState', data)
    } else {
      console.error(logClass, logMethod, 'unknown key for store:', data.key)
    }
  },
  generateDefaultLabel(vuexContext, compensationObjectParam) {
    const languageCode = this.app.i18n.locale
    const routeName = `${compensationObjectParam}___${languageCode}`
    const routeObject = calculatorRoutes(languageCode, this.app.store).find(route => route.name === routeName)
    let parentPath = routeObject.props.routeConfig.parentRoutePath
    let titles = []
    if (routeObject.props.routeConfig.elementType == 'event_types') {
      titles.push(routeObject.props.routeConfig.title)
    }
    while (parentPath && !isCalculatorRootRoute(parentPath, languageCode, this.app.store)) {
      let parentObject = route(parentPath, this.app.store)
      titles.push(parentObject.props.routeConfig.title)
      parentPath = parentObject.props.routeConfig.parentRoutePath
    }
    const defaultLabel = titles.reverse().join(' > ')
    vuexContext.commit('mutateDefaultLabel', defaultLabel)
  },
  generateDefaultSubject(vuexContext) {
    const firstname = this.app.store.getters['store__app__customer/getFirstname']
    const lastname = this.app.store.getters['store__app__customer/getLastname']
    const defaultSubject = `${firstname} ${lastname}`
    vuexContext.commit('mutateDefaultSubject', defaultSubject)
  }
}

export const getters = {
  getInputValue: state => key => {
    return state[key]
  },
  getDefaultLabel(state) {
    return state.defaultLabel
  },
  getDefaultSubject(state) {
    return state.defaultSubject
  },
  isOrderValid(state, _getters, _whatsThat, globalGetters) {
    const isAuthTypeLogin = state.authType == 'authTypeLogin'
    const isLoggedIn = globalGetters['store__app__user/isLoggedIn']
    return !isAuthTypeLogin || isLoggedIn
  }
}
