import gql from 'graphql-tag'

export const UsersMeQuery = gql`
  query usersMeQuery {
    users_me {
      id
      email
      first_name
      last_name
    }
  }
`
