import { isBusinessCustomerPath, isLanguageRootPath } from '~/helpers/routes-helper'

export var LayoutMixin = {
  computed: {
    currentDeviceClass() {
      if (this.$device.isDesktop && this.getCssBreakpoint === 'desktop') {
        return 'device__desktop'
      } else if (this.$device.isTablet && this.getCssBreakpoint?.includes('tablet')) {
        return 'device__tablet'
      } else if (this.getCssBreakpoint === 'mobile' || this.$device.isMobile) {
        // set device-class to show responsive mobile layout
        return 'device__mobile device__mobile--rwd'
      } else if (this.$device.isMobile) {
        // check if UserAgent is a  Mobile-device
        // set device-class to show progressiveWebApp layout
        return 'device__mobile device__mobile--pwa'
      } else {
        return ''
      }
    },
    getCssBreakpoint() {
      if (process.client) {
        return (
          window.getComputedStyle(document.documentElement).getPropertyValue('--current-css-breakpoint').trim() || false
        )
      }
    }
  },
  methods: {
    getCurrentPageRootCssClass(path) {
      if (isLanguageRootPath(path)) {
        return 'page--initial'
      } else if (isBusinessCustomerPath(path, this.$store)) {
        return 'page--business'
      } else {
        return 'page--private'
      }
    },
    setDefaultI18n() {
      if (!this.$i18n.locale) {
        this.$i18n.locale = 'de'
      }
    }
  }
}
