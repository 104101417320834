export var ScrollDownMixin = {
  methods: {
    scrollDown() {
      const firstMainSectionElement = document.body.querySelector('#app .page__content')

      firstMainSectionElement &&
        window.scrollTo({
          top: firstMainSectionElement.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
    }
  }
}
