import { render, staticRenderFns } from "./progressiveWebApp.vue?vue&type=template&id=b0d6ed68&lang=pug&"
import script from "./progressiveWebApp.vue?vue&type=script&lang=js&"
export * from "./progressiveWebApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppStatusBox: require('/co2unt/services/customer-app/components/app/app__status-box/app__status-box.vue').default,TheMainNav: require('/co2unt/services/customer-app/components/the-main-nav/the-main-nav.vue').default,MobileTheSplashscreen: require('/co2unt/services/customer-app/components/mobile/mobile__the-splashscreen/mobile__the-splashscreen.vue').default})
