
export default {
  name: 'AppImage',
  props: {
    image: {
      type: Object,
      filename_disk: {
        type: String,
        required: true
      },
      title: {
        type: String
      }
    },
    src: {
      type: String
    },
    classProp: {
      type: String
    }
  },
  computed: {
    imageSrc() {
      const imageUrl = `${this.$config.backofficeUrl}/assets/${this.image.filename_disk}`
      if (!this.$device.isMobile) return imageUrl

      return `${imageUrl}?quality=10`
    }
  }
}
