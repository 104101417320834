
export default {
  name: 'CalculatorBreadcrumb',
  props: {
    calculatorSteps: {
      type: Number,
      default: 3
    },
    currentCalculatorStep: {
      type: Number,
      default: 0
    }
  }
}
