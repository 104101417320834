import gql from 'graphql-tag'

export const RolesFilteredForCustomerRoleQuery = gql`
  query rolesFilteredForCustomerRole {
    roles(filter: { name: { _eq: "Endkunde" } }) {
      id
      name
    }
  }
`

export const RolesFilteredForUserQuery = gql`
  query rolesFilteredForUser {
    roles(filter: { id: { _eq: "$CURRENT_ROLE" } }) {
      id
      name
    }
  }
`
