import { BUSINESS_CUSTOMER_STRING, CALCULATOR_ROOT_STRING, PRIVATE_CUSTOMER_STRING } from '~/constants'
import { languageCodeByPath } from '~/helpers/translation-helper'

export function certificatePath(thisContext, trackingId, languageCode) {
  const locale = thisContext.$i18n.locale
  thisContext.$i18n.setLocale(languageCode)
  const location = thisContext.localePath({
    name: 'orderShow',
    params: { trackingId: trackingId }
  })
  const resolve = thisContext.$router.resolve(location)
  const path = resolve.route.path
  thisContext.$i18n.setLocale(locale)
  return path
}

export const loginPath = (thisContext, store, path) => store.getters['store__app__routes/loginPath'](path, thisContext)

export function orderCompletePath(thisContext, store, path, trackingId) {
  const location = thisContext.localeLocation({
    name: 'customerTypeSlug-orderComplete',
    params: {
      customerTypeSlug: customerTypeSlugForPathOrDefault(path, store)
    },
    query: {
      trackingId: trackingId
    }
  })
  const resolve = thisContext.$router.resolve(location)
  return resolve.route.fullPath
}

export function orderCreatePath(thisContext, store, path) {
  const location = thisContext.localeLocation({
    name: 'customerTypeSlug-orderCreate',
    params: {
      customerTypeSlug: customerTypeSlugForPathOrDefault(path, store)
    }
  })
  const resolve = thisContext.$router.resolve(location)
  return resolve.route.path
}

export function orderOverviewPath(thisContext, store, path) {
  return store.getters['store__app__routes/orderOverviewPath'](path, thisContext)
}

export function isLanguageRootPath(path) {
  return !!path.match(/^\/\w{2}\/{0,1}$/)
}

export function customerTypeSlugForPath(path) {
  return path.split('/')[2]
}

export function customerTypeSlugForPathOrDefault(path, store) {
  return (
    customerTypeSlugForPath(path) ||
    store.getters['store__app__routes/getCustomerTypeSlugDefault'](languageCodeByPath(path))
  )
}

export function customerTypeForPath(path, store) {
  return store.getters['store__app__routes/getCustomerType'](path)
}

export function customerTypeForPathOrDefault(path, store) {
  return customerTypeForPath(path, store) || PRIVATE_CUSTOMER_STRING
}

// routes helpers

export function route(path, store) {
  return store.getters['store__app__routes/getRoute'](sanitizePath(path))
}

export function sanitizePath(path) {
  // TODO: Move this to a middleware which changes the route
  // Do not care for trailing '/'
  return path.replace(/\/*$/, '')
}

export const allRoutes = (languageCode, store) => store.getters['store__app__routes/getLocalizedRoutes'](languageCode)

export const cmsRoutes = (languageCode, store) =>
  store.getters['store__app__routes/getLocalizedCmsRoutes'](languageCode)

export const calculatorRoutes = (languageCode, store) =>
  store.getters['store__app__routes/getLocalizedRoutes'](
    store.getters['store__app__routes/getCalculatorRoutes'],
    languageCode
  )

export const rootRoutes = (languageCode, store) =>
  store.getters['store__app__routes/getLocalizedRootRoutes'](languageCode)

export function rootPathAccordingToPath(path, languageCode, store) {
  return rootRoutes(languageCode, store).find(
    route => route.props.routeConfig.customerType == customerTypeForPathOrDefault(path, store)
  ).path
}

export const privateCustomerRootPath = (languageCode, store) =>
  store.getters['store__app__routes/privateCustomerRootPath'](languageCode)

export const businessCustomerRootPath = (languageCode, store) =>
  store.getters['store__app__routes/businessCustomerRootPath'](languageCode)

export const isBusinessCustomerRoute = routeObject =>
  routeObject.props.routeConfig.customerType == BUSINESS_CUSTOMER_STRING

export const isPrivateCustomerRoute = routeObject =>
  routeObject.props.routeConfig.customerType == PRIVATE_CUSTOMER_STRING

export function isBusinessCustomerPath(path, store) {
  const routeForPath = route(path, store)
  if (routeForPath) {
    return isBusinessCustomerRoute(routeForPath)
  } else {
    // fallback for application routes
    return customerTypeSlugForPathOrDefault(path, store) == BUSINESS_CUSTOMER_STRING
  }
}

export function isPrivateCustomerPath(path, store) {
  const routeForPath = route(path, store)
  if (routeForPath) {
    return isPrivateCustomerRoute(routeForPath)
  } else {
    // fallback for application routes
    return customerTypeSlugForPathOrDefault(path, store) == PRIVATE_CUSTOMER_STRING
  }
}

export function privateCustomerCalculatorRootRoute(languageCode, store) {
  return calculatorRoutes(languageCode, store).find(
    route => isPrivateCustomerRoute(route) && route.props.routeConfig.elementType == CALCULATOR_ROOT_STRING
  )
}

export function privateCustomerCalculatorRootPath(languageCode, store) {
  return privateCustomerCalculatorRootRoute(languageCode, store).path
}

export function businessCustomerCalculatorRootPath(languageCode, store) {
  return calculatorRoutes(languageCode, store).find(
    route => isBusinessCustomerRoute(route) && route.props.routeConfig.elementType == CALCULATOR_ROOT_STRING
  ).path
}

export function calculatorRootPathAccordingToPath(path, languageCode, store) {
  return calculatorRoutes(languageCode, store).find(
    route =>
      route.props.routeConfig.customerType == customerTypeForPathOrDefault(path, store) &&
      route.props.routeConfig.elementType == CALCULATOR_ROOT_STRING
  ).path
}

export function isCalculatorRoute(path, languageCode, store) {
  return !!getCalculatorRouteObject(path, languageCode, store)
}

export function isCalculatorRootRoute(path, languageCode, store) {
  return (
    privateCustomerCalculatorRootPath(languageCode, store) == path ||
    businessCustomerCalculatorRootPath(languageCode, store) == path
  )
}

export function getCalculatorRouteObject(path, languageCode, store) {
  return calculatorRoutes(languageCode, store).find(route => route.path === path)
}

export function allCmsRoutesAccordingToPath(path, languageCode, store) {
  let rootPath = rootPathAccordingToPath(path, languageCode, store)
  return cmsRoutes(languageCode, store).filter(route => route.path.startsWith(rootPath))
}
