import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4d9d0b1c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _01da2be7 = () => interopDefault(import('../pages/sandbox.vue' /* webpackChunkName: "pages/sandbox" */))
const _64cd3a29 = () => interopDefault(import('../pages/business/orderCreateWithoutPayment.vue' /* webpackChunkName: "pages/business/orderCreateWithoutPayment" */))
const _2f96ceea = () => interopDefault(import('../pages/orderShow.vue' /* webpackChunkName: "pages/orderShow" */))
const _e48477f6 = () => interopDefault(import('../pages/_customerTypeSlug/index.vue' /* webpackChunkName: "pages/_customerTypeSlug/index" */))
const _5765671c = () => interopDefault(import('../pages/_customerTypeSlug/login.vue' /* webpackChunkName: "pages/_customerTypeSlug/login" */))
const _31fa50dd = () => interopDefault(import('../pages/_customerTypeSlug/orderCreate.vue' /* webpackChunkName: "pages/_customerTypeSlug/orderCreate" */))
const _263f689a = () => interopDefault(import('../pages/_customerTypeSlug/orderOverview.vue' /* webpackChunkName: "pages/_customerTypeSlug/orderOverview" */))
const _bf6659e0 = () => interopDefault(import('../pages/_customerTypeSlug/projectSelection.vue' /* webpackChunkName: "pages/_customerTypeSlug/projectSelection" */))
const _3c3400d6 = () => interopDefault(import('../pages/_customerTypeSlug/registration.vue' /* webpackChunkName: "pages/_customerTypeSlug/registration" */))
const _27db7caa = () => interopDefault(import('../pages/_customerTypeSlug/registrationConfirmation.vue' /* webpackChunkName: "pages/_customerTypeSlug/registrationConfirmation" */))
const _67a3cc5a = () => interopDefault(import('../pages/_customerTypeSlug/orderComplete.vue' /* webpackChunkName: "pages/_customerTypeSlug/orderComplete" */))
const _101ddadc = () => interopDefault(import('../pages/_customerTypeSlug/_.vue' /* webpackChunkName: "pages/_customerTypeSlug/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _4d9d0b1c,
    name: "index___de"
  }, {
    path: "/en",
    component: _4d9d0b1c,
    name: "index___en"
  }, {
    path: "/de/frontend-sandbox",
    component: _01da2be7,
    name: "sandbox___de"
  }, {
    path: "/en/frontend-sandbox",
    component: _01da2be7,
    name: "sandbox___en"
  }, {
    path: "/de/business/manuelles_kompensieren",
    component: _64cd3a29,
    name: "business-orderCreateWithoutPayment___de"
  }, {
    path: "/en/business/compensate_manually",
    component: _64cd3a29,
    name: "business-orderCreateWithoutPayment___en"
  }, {
    path: "/de/zertifikate/:trackingId",
    component: _2f96ceea,
    name: "orderShow___de"
  }, {
    path: "/en/certificates/:trackingId",
    component: _2f96ceea,
    name: "orderShow___en"
  }, {
    path: "/de/:customerTypeSlug",
    component: _e48477f6,
    name: "customerTypeSlug___de"
  }, {
    path: "/en/:customerTypeSlug",
    component: _e48477f6,
    name: "customerTypeSlug___en"
  }, {
    path: "/de/:customerTypeSlug/anmelden",
    component: _5765671c,
    name: "customerTypeSlug-login___de"
  }, {
    path: "/de/:customerTypeSlug/bestellen",
    component: _31fa50dd,
    name: "customerTypeSlug-orderCreate___de"
  }, {
    path: "/de/:customerTypeSlug/bestelluebersicht",
    component: _263f689a,
    name: "customerTypeSlug-orderOverview___de"
  }, {
    path: "/de/:customerTypeSlug/projekt-auswaehlen",
    component: _bf6659e0,
    name: "customerTypeSlug-projectSelection___de"
  }, {
    path: "/de/:customerTypeSlug/registrierung",
    component: _3c3400d6,
    name: "customerTypeSlug-registration___de"
  }, {
    path: "/de/:customerTypeSlug/registrierung-bestaetigen",
    component: _27db7caa,
    name: "customerTypeSlug-registrationConfirmation___de"
  }, {
    path: "/en/:customerTypeSlug/confirm-registration",
    component: _27db7caa,
    name: "customerTypeSlug-registrationConfirmation___en"
  }, {
    path: "/en/:customerTypeSlug/login",
    component: _5765671c,
    name: "customerTypeSlug-login___en"
  }, {
    path: "/en/:customerTypeSlug/order",
    component: _31fa50dd,
    name: "customerTypeSlug-orderCreate___en"
  }, {
    path: "/en/:customerTypeSlug/order-overview",
    component: _263f689a,
    name: "customerTypeSlug-orderOverview___en"
  }, {
    path: "/en/:customerTypeSlug/registration",
    component: _3c3400d6,
    name: "customerTypeSlug-registration___en"
  }, {
    path: "/en/:customerTypeSlug/select-project",
    component: _bf6659e0,
    name: "customerTypeSlug-projectSelection___en"
  }, {
    path: "/de/:customerTypeSlug/bestellung/danke",
    component: _67a3cc5a,
    name: "customerTypeSlug-orderComplete___de"
  }, {
    path: "/en/:customerTypeSlug/order/thanks",
    component: _67a3cc5a,
    name: "customerTypeSlug-orderComplete___en"
  }, {
    path: "/en/:customerTypeSlug/*",
    component: _101ddadc,
    name: "customerTypeSlug-all___en"
  }, {
    path: "/de/:customerTypeSlug/*",
    component: _101ddadc,
    name: "customerTypeSlug-all___de"
  }, {
    path: "/",
    redirect: "/de/",
    name: "root"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
