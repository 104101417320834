import moment from 'moment'

export var ConvertDataMixin = {
  methods: {
    convertStringToFloat(value) {
      return parseFloat(value?.toString()?.replace(',', '.'))
    },
    convertFloatToString(value, digits = 2) {
      return value.toFixed(digits).toString().replace('.', ',')
    },
    convertFloatToStringEnglishFormat(value, digits = 2) {
      return value.toFixed(digits).replace(',', '.')
    },
    convertDateToLocalizedString(value, locale) {
      const defaultLocale = 'de'

      // TODO: if momentjs is used in multiple instances/methods. Extract this to own helper method
      moment.locale(locale || defaultLocale)
      return moment(value).format('L')
    },
    convertFloatToWeightString(weight_in_t) {
      let unit, amount
      if (weight_in_t < 1) {
        unit = 'kg'
        amount = weight_in_t * 1000
      } else {
        unit = 't'
        amount = weight_in_t
      }
      return `${this.convertFloatToString(amount, 2)} ${unit}`
    }
  }
}
