import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0d0ab3f8&lang=pug&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppStatusBox: require('/co2unt/services/customer-app/components/app/app__status-box/app__status-box.vue').default,TheHeader: require('/co2unt/services/customer-app/components/the-header/the-header.vue').default,TheHeroStage: require('/co2unt/services/customer-app/components/the-hero-stage/the-hero-stage.vue').default,Footer: require('/co2unt/services/customer-app/components/footer/footer.vue').default})
