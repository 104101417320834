
export default {
  name: 'FormSubmit',
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isValid: {
      type: Boolean,
      default: true
    },
    showWait: {
      type: Boolean,
      default: false
    },
    attrClass: {
      type: String,
      default: 'button button--primary'
    },
    attrSVGName: {
      type: String,
      default: 'icon-arrow-long'
    }
  }
}
