// Cache implementation
import { InMemoryCache } from '@apollo/client/core'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        // there are no items anymore
        // items: {
        //   // prefer incoming item lists for query type
        //   merge: false
        // }
      }
    }
  }
})

// initial local store declaration
