import { RolesFilteredForUserQuery } from '~/plugins/graphql'

export const state = () => ({
  pending: false,
  success: undefined
})

export const mutations = {
  mutatePending(state, data) {
    state.success = undefined
    state.pending = data.flag
  },
  mutateSuccess(state, data) {
    state.pending = false
    state.success = data.flag
  }
}

export const actions = {
  async getRoleForUser(vuexContext) {
    vuexContext.commit('mutatePending', { flag: true })

    await this.app.apolloProvider.defaultClient
      .query({
        query: RolesFilteredForUserQuery,
        fetchPolicy: 'network-only'
      })
      .then(async response => {
        vuexContext.commit('mutateSuccess', { flag: true })

        const role = response?.data?.roles[0]
        if (!!role) {
          await this.app.store.commit('store__app__user/mutateRoleForUser', role)
        }
      })
      .catch(error => {
        vuexContext.commit('mutateSuccess', { flag: false })

        console.error(error)
      })

    vuexContext.commit('mutatePending', { flag: false })
  }
}

export const getters = {
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}
