
import { allowsInput } from '~/helpers/calculator-item-helper'
import { languageCodeByPath } from '~/helpers/translation-helper'
import { ConvertDataMixin, FootprintCalculatorMixin, RoutesMixin, TranslationMixin } from '~/mixins'

export default {
  name: 'Calculator',
  mixins: [ConvertDataMixin, FootprintCalculatorMixin, RoutesMixin, TranslationMixin],
  data() {
    return {
      calculatorData: {},
      items: [],
      parentItem: undefined,
      videoFileFromDirectus: undefined
    }
  },
  computed: {
    linkBackPath() {
      const routeConfig = this.route(this.$router.currentRoute.path).props.routeConfig
      return routeConfig.parentRoutePath || this.calculatorRootPathAccordingToPath(this.$router.currentRoute.path)
    },
    currentCalculatorStep() {
      const routeSegments = this.$router.currentRoute.path.split('/')
      const calculatorSegmentIndex = routeSegments.indexOf(this.$t('routes.co2Calculator.index'))
      return routeSegments.length - (calculatorSegmentIndex + 1)
    },
    calculatorSteps() {
      const routeSegments = this.$router.currentRoute.path.split('/')
      const calculatorSegmentIndex = routeSegments.indexOf(this.$t('routes.co2Calculator.index'))
      return routeSegments.length - (calculatorSegmentIndex + 1) || 3
    },
    currentRouteFromStore() {
      return this.route(this.$router.currentRoute.path)
    },
    isBaseEventType() {
      return this.currentRouteFromStore.props.routeConfig.isBaseEventType
    },
    parentInputType() {
      return allowsInput(this.parentItem) ? this.parentItem.calculator_input : undefined
    },
    rootEventTypes() {
      return this.calculatorData.event_types.filter(event_type => event_type.parent_event_type === null)
    },
    translatedHeadline() {
      if (this.parentItem && this.parentItem.translations) {
        const translation = this.getTranslation(this.parentItem.translations)
        return translation.question || translation.name
      } else {
        return this.$t('pages.co2Calculator.headline')
      }
    }
  },
  created() {
    this.calculatorData = this.$store.getters['store__api__routes/getCalculatorData']
    this.decideWhichComponentIsRendered()

    if (this.isFootprintCalculatorPage) {
      this.footprintCalculatorHook()
    }
    this.$store.dispatch(
      'store__app__footprint__calculator/setFootprintCalculatorLanguage',
      languageCodeByPath(this.$router.currentRoute.path)
    )
  },
  watch: {
    parentItem() {
      let result

      if (this.$device.isMobile) {
        result = this.parentItem.video_mobile?.filename_disk
      } else {
        result = this.parentItem.video?.filename_disk
      }

      this.videoFileFromDirectus = result
    }
  },
  methods: {
    getComponentForCalculatorInputType(item) {
      switch (item.__typename) {
        case 'calculator_input':
          return 'CalculatorCardInput'
        case 'event_types':
          return 'CalculatorCardLink'
      }
    },
    decideWhichComponentIsRendered() {
      const path = this.$router.currentRoute.path
      this.parentItem = {}

      if (this.isCalculatorRootRoute(path)) return (this.items = this.rootEventTypes)
      if (this.isFootprintRootPage) return this.footprintCalculatorHook()

      this.setInputTypes()
    },
    setInputTypes() {
      if (this.currentRouteFromStore) {
        this.items = this.getItems()
      }
    },
    getItems() {
      const parentId = this.currentRouteFromStore.props.routeConfig.id

      this.setParentItem(parentId)
      return this.getInputTypes(parentId)
    },
    setParentItem(parentId) {
      const parentType = this.currentRouteFromStore.props.routeConfig.elementType

      this.parentItem = this.calculatorData[parentType].find(inputType => inputType.id == parentId) || {}
    },
    getInputTypes(parentId) {
      if (this.parentItem.sub_event_types.length > 0) {
        return this.getChildInputTypes(parentId)
      }

      if (this.parentItem.calculator_input.length > 0) {
        return this.parentInputType
      }

      throw new Error('Invalid configuration for event type %O', this.parentItem.id)
    },
    getChildInputTypes(parentId) {
      return this.calculatorData.event_types.filter(inputType => inputType.parent_event_type?.id == parentId)
    }
  }
}
