import { UsersMeQuery } from '~/plugins/graphql'

export const state = () => ({
  pending: false,
  success: undefined
})

export const mutations = {
  mutatePending(state, data) {
    state.success = undefined
    state.pending = data.flag
  },
  mutateSuccess(state, data) {
    state.pending = false
    state.success = data.flag
  }
}

export const actions = {
  async getUsersMe(vuexContext) {
    vuexContext.commit('mutatePending', { flag: true })

    await this.app.apolloProvider.defaultClient
      .query({
        query: UsersMeQuery,
        fetchPolicy: 'network-only'
      })
      .then(response => {
        vuexContext.commit('mutateSuccess', { flag: true })

        const usersMe = response?.data?.users_me
        if (!!usersMe) {
          this.app.store.commit('store__app__user/mutateUsersMe', usersMe)
        }
      })
      .catch(error => {
        vuexContext.commit('mutateSuccess', { flag: false })

        console.error(error)
      })
  }
}

export const getters = {
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}
