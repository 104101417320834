
import { ScrollDownMixin } from '~/mixins'

export default {
  name: 'TheButtonScrolldown',
  mixins: [ScrollDownMixin],
  computed: {
    isVisible() {
      return this.$store.getters['store__app/isShowTheButtonScrolldown']
    }
  },
  props: {
    initialShown: {
      type: Boolean,
      default: false
    }
  }
}
