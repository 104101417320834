
const logClass = '[AppVideoDefault]'

export default {
  name: 'AppVideoDefault',
  data() {
    return {
      isAutoplayVideo: true,
      showPauseStyle: false,
      videoSource: '/assets/videos/video_loop_alternative_1080p50_VBR_8-12.mp4'
    }
  },
  mounted() {
    this.setVideoAttributes()
  },
  methods: {
    setVideoAttributes() {
      if (this.$refs.currentVideo) {
        // slowdown default-video play-speed
        this.$refs.currentVideo.playbackRate = 0.4
      }

      // set autoplay dynamically
      if (this.autoplayVideo) {
        this.isAutoplayVideo = 'autoplay'
        this.showPauseStyle = false
      } else {
        this.isAutoplayVideo = false
        this.showPauseStyle = true
      }
    },
    onClickTogglePlay() {
      if (this.$refs.currentVideo.paused) {
        this.$refs.currentVideo.play()
        this.showPauseStyle = false
      } else {
        this.$refs.currentVideo.pause()
        this.showPauseStyle = true
      }
    }
  }
}
