import { render, staticRenderFns } from "./form__certificate-check.vue?vue&type=template&id=7abb8318&lang=pug&"
import script from "./form__certificate-check.vue?vue&type=script&lang=js&"
export * from "./form__certificate-check.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormSubmit: require('/co2unt/services/customer-app/components/form/form__submit/form__submit.vue').default})
