import { authUserMutation } from '~/plugins/graphql'

export const state = () => ({
  pending: false,
  success: undefined
})

export const mutations = {
  mutatePending(state, data) {
    state.pending = data.flag
  },
  mutateSuccess(state, data) {
    state.success = data.flag
  }
}

export const actions = {
  async authenticate(vuexContext, data) {
    return new Promise((resolve, reject) => {
      vuexContext.commit('mutatePending', { flag: true })
      vuexContext.commit('mutateSuccess', { success: undefined })

      this.app.apolloProvider.defaultClient
        .mutate({
          mutation: authUserMutation,
          variables: { email: data.email, password: data.password }
        })
        .then(response => {
          vuexContext.commit('mutatePending', { flag: false })
          vuexContext.commit('mutateSuccess', { flag: true })

          resolve({
            accessToken: response.data.auth_login.access_token,
            refreshToken: response.data.auth_login.refresh_token,
            expirationDate: new Date().getTime() + Number.parseInt(response.data.auth_login.expires)
          })
        })
        .catch(error => {
          vuexContext.commit('mutatePending', { flag: false })
          vuexContext.commit('mutateSuccess', { flag: false })

          let errorMessageKey
          if (error.message === 'GraphQL error: User suspended.') {
            errorMessageKey = 'loginFailedUserLocked'
          } else if (error.message === 'GraphQL error: Invalid user credentials.') {
            errorMessageKey = 'loginFailedInvalidCredentials'
          } else {
            errorMessageKey = 'loginFailedGeneric'
            this.$sentry.captureException(error)
          }

          reject(errorMessageKey)
        })
    })
  }
}

export const getters = {
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}
