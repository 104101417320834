import GoogleTagManagerRenderScriptDecision from '~/plugins/googleTagManager/renderScriptDecision'

export default function (nuxtContext) {
  if (!shouldRenderBodyScript(nuxtContext)) return

  const gtmId = nuxtContext.context.$config.gtmId
  const gtmScript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`

  exchangeScriptBeforeBody(gtmScript)
}

function shouldRenderBodyScript(nuxtContext) {
  return GoogleTagManagerRenderScriptDecision(nuxtContext.context.$config.containerMode)
}

function exchangeScriptBeforeBody(gtmScript) {
  const parent = document.body
  const firstChild = setupFirstChild(gtmScript)

  const newElement = document.createElement('noscript')
  newElement.innerHTML = gtmScript

  parent.insertBefore(newElement, firstChild)
}

function setupFirstChild(gtmScript) {
  let firstChild = document.body.firstChild

  if (scriptAlreadyExists(firstChild, gtmScript)) {
    firstChild.remove()

    firstChild = document.body.firstChild
  }

  return firstChild
}

function scriptAlreadyExists(element, gtmScript) {
  return element.innerHTML == gtmScript
}
