import { AllowedObjectTypesForLabelGenerationQuery, CompensationObjectTypesQuery } from '~/plugins/graphql/queries'

const logClass = '[StoreApiOrder]'

export const state = () => ({
  errorMessages: [],
  pending: false,
  success: undefined
})

export const mutations = {
  mutateAddErrorMessage(state, data) {
    state.errorMessages.push(data)
  },
  mutatePending(state, data) {
    state.success = undefined
    state.pending = data
  },
  mutateSuccess(state, data) {
    state.pending = false
    state.success = data
  }
}

export const actions = {
  async getAllowedObjectTypesForLabelGeneration(vuexContext) {
    const logMethod = '[getAllowedObjectTypesForLabelGeneration]'

    vuexContext.commit('mutatePending', { flag: true })
    vuexContext.commit('mutateSuccess', { success: undefined })

    await this.app.apolloProvider.defaultClient
      .query({
        query: AllowedObjectTypesForLabelGenerationQuery
      })
      .then(response => {
        vuexContext.commit('mutateSuccess', { flag: true })

        const allowedObjectTypesForLabelGeneration = response.data.allowedObjectTypesForLabelGeneration
        if (allowedObjectTypesForLabelGeneration) {
          this.app.store.commit(
            'store__app__order/mutateAllowedObjectTypesForLabelGeneration',
            allowedObjectTypesForLabelGeneration
          )
        }
      })
      .catch(error => {
        vuexContext.commit('mutateSuccess', { flag: false })
        console.error(logClass, logMethod, error)
      })
  },
  async getCompensationObjectTypes(vuexContext) {
    const logMethod = '[getCompensationObjectTypes]'

    vuexContext.commit('mutatePending', { flag: true })
    vuexContext.commit('mutateSuccess', { success: undefined })

    await this.app.apolloProvider.defaultClient
      .query({
        query: CompensationObjectTypesQuery
      })
      .then(response => {
        vuexContext.commit('mutateSuccess', { flag: true })

        const validObjectCompensationTypes = response.data.validObjectCompensationTypes
        if (validObjectCompensationTypes) {
          this.app.store.commit('store__app__order/mutateCompensationObjectTypes', validObjectCompensationTypes)
        }
      })
      .catch(error => {
        vuexContext.commit('mutateSuccess', { flag: false })
        console.error(logClass, logMethod, error)
      })
  }
}
