export default {
  loginPath:
    (_store, getters) =>
    // takes `path` and `context` as arguments
    (...args) =>
      getters.getCustomerTypePath('login', ...args),

  registrationPath:
    (_store, getters) =>
    // takes `path` and `context` as arguments
    (...args) =>
      getters.getCustomerTypePath('registration', ...args),

  registrationConfirmationPath:
    (_store, getters) =>
    // takes `path` and `context` as arguments
    (...args) =>
      getters.getCustomerTypePath('registrationConfirmation', ...args),

  isLoginRoute: (_store, getters) => (route, context) => route.path == getters.loginPath(route.path, context),

  isRegistrationRoute: (_store, getters) => (route, context) =>
    route.path == getters.registrationPath(route.path, context),

  isRegistrationConfirmationRoute: (_store, getters) => (route, context) =>
    route.path == getters.registrationConfirmationPath(route.path, context)
}
