export const state = () => ({
  allowedObjectTypesForLabelGeneration: [],
  compensationObjectTypes: [],
  count: 0,
  myOrders: []
})

export const mutations = {
  mutateAllowedObjectTypesForLabelGeneration(state, data) {
    state.allowedObjectTypesForLabelGeneration = data
  },
  mutateCompensationObjectTypes(state, data) {
    state.compensationObjectTypes = data
  },
  mutateCount(state, data) {
    state.count = data
  },
  mutateMyOrders(state, data) {
    state.myOrders = data
  }
}

export const actions = {
  async setAllowedObjectTypesForLabelGeneration({}) {
    await this.app.store.dispatch('store__api__order/getAllowedObjectTypesForLabelGeneration')
  },
  async setCompensationObjectTypes({}) {
    await this.app.store.dispatch('store__api__order/getCompensationObjectTypes')
  },
  async aggregateOrdersCount({}) {
    await this.app.store.dispatch('api/order/count/call')
  },
  async queryMyOrders(_, data) {
    await this.app.store.dispatch('api/order/all/call', data)
  }
}

export const getters = {
  getAllowedObjectTypesForLabelGeneration(state) {
    return state.allowedObjectTypesForLabelGeneration
  },
  getCompensationObjectTypes(state) {
    return state.compensationObjectTypes
  },
  getCount(state) {
    return state.count
  },
  getMyOrders(state) {
    return state.myOrders
  }
}
