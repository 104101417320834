import Vue from 'vue'

const logClass = '[StoreAppRegistration]'

export const state = () => ({})

export const mutations = {
  mutateState(state, data) {
    Vue.set(state, data.key, data.value)
  }
}

export const actions = {
  setInputValue(vuexContext, data) {
    const logMethod = '[setInputValue]'
    const valid_data = ['firstname', 'lastname', 'street', 'housenumber', 'zipcode', 'city', 'email']
    if (valid_data.includes(data.key)) {
      vuexContext.commit('mutateState', data)
    } else {
      console.error(logClass, logMethod, 'unknown key for store:', data.key)
    }
  }
}

export const getters = {
  getInputValue: state => key => {
    return state[key]
  },
  getAddress(state) {
    return {
      firstname: state.firstname,
      lastname: state.lastname,
      street: state.street,
      housenumber: state.housenumber,
      zipcode: state.zipcode,
      city: state.city,
      email: state.email
    }
  }
}
