import gql from 'graphql-tag'

export const authUserMutation = gql`
  mutation authUser($email: String!, $password: String!) {
    auth_login(email: $email, password: $password) {
      expires
      access_token
      refresh_token
    }
  }
`

export const authRefreshMutation = gql`
  mutation authRefresh($refresh_token: String!) {
    auth_refresh(refresh_token: $refresh_token) {
      expires
      access_token
      refresh_token
    }
  }
`

export const logoutUserMutation = gql`
  mutation logoutUser($refresh_token: String!) {
    auth_logout(refresh_token: $refresh_token)
  }
`

export const confirmRegistrationUserMutation = gql`
  mutation confirmRegistrationUser($token: String!, $password: String!) {
    users_invite_accept(token: $token, password: $password)
  }
`
