
import { TranslationMixin } from '~/mixins'

export default {
  name: 'CmsPageContent',
  mixins: [TranslationMixin],
  props: {
    page: {
      type: Object,
      required: true,
      page_sections: {
        type: Array,
        required: true,
        item: {
          type: Object,
          required: true,
          id: {
            type: Number,
            required: true
          }
        }
      }
    }
  },
  beforeUpdate() {
    if (this.page === undefined) {
      console.debug('[CmsPageContent]', '[beforeUpdate]', 'this.page is undefined, redirecting to /')
      this.$router.push('/')
    }
  },
  methods: {
    getComponentForContentType(pageSectionItem, index) {
      if (index === 1) {
        this.showScrollDownButton()
      }

      switch (pageSectionItem.__typename) {
        case 'ps_headline_text_media':
          return 'SectionContentDefault'
        case 'ps_headline_text_teaser_items':
          return 'SectionCardList'
        case 'ps_project_grid':
          return 'SectionCardGrid'
        case 'ps_howto_steps':
          return 'SectionHowto'
        case 'ps_co2_calculator_grid':
          return 'SectionCardPairGrid'
        case 'ps_feedback_grid':
          return 'SectionTestimonialGrid'
        case 'ps_project_details':
          return 'SectionProjectDetails'
        case 'ps_grid_project_short_descriptions':
          return 'SectionContentStriped'
      }
    },
    showScrollDownButton() {
      this.$store.dispatch('store__app/setShowTheButtonScrolldown', { flag: true })
    }
  }
}
