
import { TranslationMixin } from '~/mixins'
import { pageQuery } from '~/plugins/graphql'

export default {
  name: 'CmsPage',
  mixins: [TranslationMixin],
  props: {
    pageId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      page: {},
      stage: {
        component: undefined,
        item: {
          translations: []
        }
      }
    }
  },
  async fetch() {
    await this.$apollo.queries.page.observer.result()
  },
  apollo: {
    page: {
      deep: true,
      query: pageQuery,
      update: data => ({ ...data.pages[0] }),
      variables() {
        return {
          locale: this.locale,
          pageId: this.pageId
        }
      }
    }
  },
  watch: {
    page(newPage) {
      this.page = newPage
      if (this.page && this.page.stage) {
        let item = this.page.stage[0]?.item
        if (item) {
          if (['project_stage', 'pages_stage'].includes(item.__typename)) {
            // if linked render the project stage, otherwise the stage of the page
            item = item?.project?.project_stage[0] ? item.project.project_stage[0] : item
            this.stage = { component: 'ProjectStage', item: item }
          }
        }
      }
    }
  }
}
