import slugify from 'slugify'

export function slugifyEvent(translation, eventType) {
  return slugifyString(translation?.name || translation?.label || eventType.id)
}

export function slugifyPage(translation, page) {
  return slugifyString(translation.slug || translation.title || page.id)
}

function slugifyString(string) {
  return slugify(string.replace('₂', '2'), { lower: true })
}
