import gql from 'graphql-tag'

import { translationsFilter } from '../filters'

export const disclaimerQuery = gql`
  query disclaimersByTranslation($locale: String!) {
    disclaimers(limit: -1, sort: "sort") {
      id
      sort
      ${translationsFilter()} {
        languages_code { code }
        headline
        description
      }
    }
  }
`
