import gql from 'graphql-tag'

export const registerCustomerMutation = gql`
  mutation createCustomer($address: AddressInput!, $customerName: String!, $userRegistrationUrl: String!) {
    createCustomer(
      input: {
        addressInput: $address
        customerInput: { name: $customerName }
        registrationInput: { url: $userRegistrationUrl }
      }
    ) {
      customer {
        id
        name
      }
    }
  }
`
