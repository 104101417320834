import { render, staticRenderFns } from "./_.vue?vue&type=template&id=1efa37c8&lang=pug&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Calculator: require('/co2unt/services/customer-app/components/calculator/calculator.vue').default,CmsPage: require('/co2unt/services/customer-app/components/cms-page/cms-page.vue').default})
