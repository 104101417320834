
import { ComparisonSliderMixin, RoutesMixin, TranslationMixin, ScrollDownMixin } from '~/mixins'

export default {
  name: 'BusinessCustomerSection',
  mixins: [ComparisonSliderMixin, RoutesMixin, TranslationMixin, ScrollDownMixin],
  props: {
    item: {
      type: Object,
      required: true,
      translations: {
        type: Array,
        required: true
      }
    },
    showSecondContent: {
      type: Boolean,
      required: true
    }
  }
}
