import Cookie from 'js-cookie'

export function extractFromCookie(req, key) {
  let cookie

  // server side
  if (req) {
    cookie = req.headers.cookie?.split(';')?.find(cookie => cookie.trim().startsWith(`${key}=`))
  }

  return cookie ? cookie.split('=')[1] : Cookie.get(key)
}

export function removeKeysFromCookie(keys) {
  keys.forEach(key => {
    removeKeyFromCookie(key)
  })
}

export function removeKeyFromCookie(key) {
  Cookie.remove(key)
}

export function setUserCookies(userObject) {
  Cookie.set('accessToken', userObject.accessToken)
  Cookie.set('email', userObject.email)
  Cookie.set('expirationDate', userObject.expirationDate.toString())
  Cookie.set('refreshToken', userObject.refreshToken)
}

export function setFootprintCalculatorCookie(footprintCalculatorSteps, footprintCalculatorStepsExpirationDate) {
  Cookie.remove('footprintCalculatorSteps')

  const cookieizedFootprintCalculatorSteps = footprintCalculatorSteps.map(footprintCalculatorStep => {
    return {
      co2Factor: footprintCalculatorStep.co2Factor,
      inputValue: footprintCalculatorStep.inputValue,
      name: footprintCalculatorStep.eventTypeRootRoute.name,
      inputTypeId: footprintCalculatorStep.inputTypeId
    }
  })

  Cookie.set('footprintCalculatorSteps', JSON.stringify(cookieizedFootprintCalculatorSteps))
  Cookie.set('footprintCalculatorStepsExpirationDate', footprintCalculatorStepsExpirationDate)
}

export function setFlashMessageCookie(message, type) {
  Cookie.set('flashMessage', JSON.stringify({ message: message, type: type }))
}

export function sanitizeCookieFromServer(cookieFromServer) {
  if (!cookieFromServer) return null

  const stringifiedCookie = `${cookieFromServer}`
  const replacedSymbolsFromCookie = stringifiedCookie.replaceAll('%2C', ', ')
  const jsonifiedCookie = replacedSymbolsFromCookie.split('%22').join('"')
  return JSON.parse(jsonifiedCookie)
}

// TODO: set cookies if they are persisted in state
// export function setOrderCookies() {
// }
//
// export function setCustomerCookies() {
// }
