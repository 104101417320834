
import { RoutesMixin } from '~/mixins'

export default {
  name: 'AppLinkBack',
  mixins: [RoutesMixin],
  props: {
    path: {
      type: String,
      required: true
    }
  },
  computed: {
    isButtonDisabled() {
      return this.isCalculatorRootRoute(this.$router.currentRoute.path) || !this.path
    }
  },
  methods: {
    goBack() {
      this.$router.push(this.path)
    }
  }
}
