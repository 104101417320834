import { render, staticRenderFns } from "./the-hero-stage--image.vue?vue&type=template&id=d1c4bf92&lang=pug&"
import script from "./the-hero-stage--image.vue?vue&type=script&lang=js&"
export * from "./the-hero-stage--image.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/co2unt/services/customer-app/components/app/app__image/app__image.vue').default})
