import { extractFromCookie, removeKeyFromCookie } from '~/helpers/cookie-helper.js'

export var FlashMessageMixin = {
  mounted() {
    let flashMessage = extractFromCookie(null, 'flashMessage')

    if (flashMessage) {
      flashMessage = JSON.parse(flashMessage)
      this.$store.dispatch('store__app/setAppStatusMessage', {
        message: flashMessage.message,
        type: flashMessage.type
      })

      removeKeyFromCookie('flashMessage')
    }
  }
}
