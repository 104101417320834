import { confirmRegistrationUserMutation } from '~/plugins/graphql'

const logClass = '[StoreApiRegistrationConfirmation]'

export const state = () => ({
  pending: false,
  success: undefined
})

export const mutations = {
  mutatePending(state, data) {
    state.pending = data.flag
  },
  mutateSuccess(state, data) {
    state.success = data.flag
  }
}

export const actions = {
  async confirm_registration(vuexContext, data) {
    const logMethod = '[confirm_registration]'

    vuexContext.commit('mutatePending', { flag: true })
    vuexContext.commit('mutateSuccess', { success: undefined })

    await this.app.apolloProvider.defaultClient
      .mutate({
        mutation: confirmRegistrationUserMutation,
        variables: {
          token: data.token,
          password: data.password
        }
      })
      .then(response => {
        if (response?.data?.users_invite_accept === true) {
          vuexContext.commit('mutateSuccess', { flag: true })
        } else {
          console.error(logClass, logMethod, 'response:', response)

          this.app.store.dispatch('store__app/setAppStatusErrorMessage', {
            message: this.app.i18n.t('pages.registrationConfirmation.messages.registrationConfirmationFailedGeneric')
          })

          vuexContext.commit('mutateSuccess', { flag: false })
        }
      })
      .catch(error => {
        let errorMessageKey

        if (error.message.includes('GraphQL error: Email address') && error.message.includes("hasn't been invited.")) {
          errorMessageKey = 'registrationConfirmationFailedForNotInvitedEmailAddress'
        } else {
          errorMessageKey = 'registrationConfirmationFailedGeneric'
          console.error(logClass, logMethod, 'error:', error)
          this.$config.$sentry.captureException(error)
        }

        this.app.store.dispatch('store__app/setAppStatusErrorMessage', {
          message: this.app.i18n.t(`pages.registrationConfirmation.messages.${errorMessageKey}`)
        })

        vuexContext.commit('mutateSuccess', { flag: false })
      })

    vuexContext.commit('mutatePending', { flag: false })
  }
}

export const getters = {
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}
