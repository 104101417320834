import gql from 'graphql-tag'

import { translationsFilter } from '../filters'

export const pagesForRoutesQuery = gql`
  query pagesForRoutes {
    pages(limit: -1, sort: "sort") {
      id
      sort
      show_in_footer
      show_in_nav_main
      parent_page {
        id
      }
      sub_pages(limit: -1) {
        id
      }
      translations {
        languages_code {
          code
        }
        slug
        title
      }
    }
  }
`

export function pageQuery() {
  return pagesQuery('$pageId: Float!, $locale: String!', ['id: { _eq: $pageId }'])
}

export function pagesQuery(queryParams, pagesFilterElements) {
  return pagesQueryWithInlineFragments(queryParams, pagesFilterElements)
}

// alias optional_headline to prevent schema conflict errors
export function pagesQueryWithInlineFragments(queryParams, pagesFilterElements) {
  return gql`
    ${queryParams ? `query pagesBySlugAndTranslation (${queryParams}) {` : 'query pagesBySlugAndTranslation {'}
      pages(limit: -1, filter: { ${pagesFilterElements.join()} }) {
        id
        status
        ${translationsFilter()} {
          languages_code { code }
          slug
        }
        parent_page {
          id
        }
        sub_pages(limit: -1) {
          id
        }
        stage {
          item {
            ... on hero_stages {
              id
              call_to_action_link_private_customer {
                id
              }
              call_to_action_link_business_customer {
                id
              }
              call_to_action_link_not_decided_customer {
                id
              }
              ${translationsFilter()} {
                languages_code { code }
                headline_private_customer
                subheadline_private_customer
                category_label_private_customer
                call_to_action_label_private_customer
                headline_business_customer
                subheadline_business_customer
                category_label_business_customer
                call_to_action_label_business_customer
                headline_not_decided_customer
                subheadline_not_decided_customer
                call_to_action_label_not_decided_customer
              }
            }
            ... on project_stage {
              project {
                project_stage {
                  media {
                    id
                    filename_disk
                    title
                    type
                  }
                  image_triple {
                    id
                    filename_disk
                    title
                    type
                  }
                }
              }
              media {
                id
                filename_disk
                title
                type
              }
              image_triple {
                id
                filename_disk
                title
                type
              }
            }
          }
        }
        page_sections(limit: -1, sort: "sort") {
          id
          sort
          item {
            ... on ps_headline_text_media {
              id
              call_to_action_link {
                id
              }
              media {
                id
                filename_disk
                title
                type
              }
              youtube
              ${translationsFilter()} {
                languages_code { code }
                optional_headline: headline
                text
                call_to_action_label
              }
            }
            ... on ps_headline_text_teaser_items {
              id
              background_image {
                id
                filename_disk
                title
              }
              call_to_action_link {
                id
              }
              ${translationsFilter()} {
                languages_code { code }
                headline
                text
                call_to_action_label
              }
              teaser_items(sort: "sort") {
                id
                sort
                teaser_items_id {
                  id
                  image {
                    id
                    filename_disk
                    title
                  }
                  ${translationsFilter()} {
                  languages_code { code }
                    headline
                  }
                }
              }
            }
            ... on ps_project_grid {
              id
              ${translationsFilter()} {
                languages_code { code }
                headline
                call_to_action_label
              }
              call_to_action_link {
                id
              }
              projects(sort: "sort") {
                id
                sort
                projects_id {
                  id
                  image {
                    id
                    filename_disk
                    type
                    title
                  }
                  page {
                    id
                    ${translationsFilter()} {
                      languages_code { code }
                      slug
                    }
                  }
                  ${translationsFilter()} {
                    languages_code { code }
                    name
                  }
                }
              }
            }
            ... on ps_howto_steps {
              id
              ${translationsFilter()} {
                languages_code { code }
                headline
                text
                step_1_headline
                step_2_headline
                step_3_headline
              }
            }
            ... on ps_co2_calculator_grid {
              id
              background_image {
                id
                filename_disk
                type
                title
              }
              ${translationsFilter()} {
                languages_code { code }
                headline
                text
              }
              event_types(sort: "sort") {
                id
                event_types_id {
                  id
                  image {
                    id
                    filename_disk
                    type
                    title
                  }
                  parent_event_type {
                    id
                  }
                  sub_event_types {
                    id
                  }
                  ${translationsFilter()} {
                    languages_code { code }
                    name
                  }
                }
              }
            }
            ... on ps_feedback_grid {
              id
              ${translationsFilter()} {
                languages_code { code }
                headline
              }
              feedbacks(sort: "sort") {
                id
                sort
                feedbacks_id {
                  id
                  name
                  image {
                    id
                    filename_disk
                    type
                    title
                  }
                  ${translationsFilter()} {
                    languages_code { code }
                    text
                  }
                }
              }
            }
            ... on ps_project_details {
              id
              project_id {
                id
                image {
                  id
                  filename_disk
                  type
                  title
                }
                un_goals {
                  un_goals_id {
                    id
                    image {
                      id
                      filename_disk
                      type
                      title
                    }
                    ${translationsFilter()} {
                      languages_code { code }
                      name
                    }
                  }
                  ${translationsFilter()} {
                    languages_code { code }
                    description
                  }
                }
                ${translationsFilter()} {
                  languages_code { code }
                  name
                  description
                  certified_by
                  certificate_name
                }
              }
              ${translationsFilter()} {
                languages_code { code }
                un_goals_headline
              }
            }
            ... on ps_grid_project_short_descriptions {
              project_short_descriptions(sort: "sort") {
                id
                image {
                  id
                  filename_disk
                  type
                  title
                }
                project_id {
                  id
                  image {
                    id
                    filename_disk
                    type
                    title
                  }
                  page {
                    id
                    ${translationsFilter()} {
                      languages_code { code }
                      slug
                    }
                  }
                  un_goals {
                    un_goals_id {
                      id
                      image {
                        id
                        filename_disk
                        type
                        title
                      }
                      ${translationsFilter()} {
                        languages_code { code }
                        name
                      }
                    }
                  }
                }
                ${translationsFilter()} {
                  languages_code { code }
                  headline
                  description
                }
              }
            }
          }
        }
      }
    }
  `
}

export function heroStagesQuery() {
  return gql`
    query heroStages($locale: String!) {
      hero_stages(limit: -1) {
        __typename
        id
        internal_title
        ${translationsFilter()} {
          languages_code {
            code
          }
          headline_not_decided_customer
          subheadline_not_decided_customer
          headline_private_customer
          headline_business_customer
          subheadline_private_customer
          subheadline_business_customer
          headline_not_decided_customer
          category_label_private_customer
          category_label_business_customer
          call_to_action_label_private_customer
          call_to_action_label_business_customer
          call_to_action_label_not_decided_customer
        }
      }
    }
  `
}
