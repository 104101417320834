import Vue from 'vue'

const logClass = '[StoreAppOrderCreateWithoutPayment]'

export const state = () => ({
  // TODO: maybe we have to exclude it from here? We should! But later
  projects: [],
  project_id: undefined,
  subject: undefined,
  label: undefined,
  co2_amount_in_kg: undefined,
  object_type: undefined
})

export const mutations = {
  mutateClearOrderData(state, _data) {
    state.project_id = undefined
    state.subject = undefined
    state.label = undefined
    state.co2_amount_in_kg = undefined
    state.object_type = undefined
  },
  mutateState(state, data) {
    Vue.set(state, data.key, data.value)
  },
  mutateDefaultLabel(state, label) {
    state.defaultLabel = label
  },
  mutateDefaultSubject(state, subject) {
    state.defaultSubject = subject
  },
  mutateSetProject(state, project) {
    state.project = project
  }
}

export const actions = {
  setInputValue(vuexContext, data) {
    const logMethod = '[setInputValue]'
    const valid_data = ['co2_amount_in_kg', 'label', 'price', 'project_id', 'subject', 'object_type', 'projects']

    if (valid_data.includes(data.key)) {
      vuexContext.commit('mutateState', data)
    } else {
      console.error(logClass, logMethod, 'unknown key for store:', data.key)
    }
  },
  createOrder(vuexContext, _data) {
    const getters = vuexContext.getters
    const result = {
      order: {
        projectId: getters.getProjectId,
        co2Amount: getters.co2AmountInT,
        price: getters.calculatedPrice,
        label: getters.getLabel,
        subject: getters.getSubject,
        object_type: getters.getObjectType
      }
    }

    this.app.store.dispatch('store__api__order__create__without__payment/create', result)
  },
  clearOrderData(vuexContext) {
    vuexContext.commit('mutateClearOrderData', {})
  }
}

export const getters = {
  getInputValue: state => key => {
    return state[key]
  },
  co2AmountInKg: state => {
    return Number.parseInt(state.co2_amount_in_kg)
  },
  co2AmountInT: (_state, getters) => {
    return getters.co2AmountInKg / 1000
  },
  calculatedPrice(_state, getters) {
    return getters.co2AmountInT * getters.getPricePerT
  },
  formattedCalculatedPrice(_state, getters) {
    return Number.parseFloat(getters.calculatedPrice.toFixed(2))
  },
  getProjectId(state) {
    return state.project_id
  },
  getProject(_state, getters) {
    return getters.getProjects.find(project => project.id === getters.getProjectId)
  },
  getProjects(state) {
    return state.projects
  },
  getPricePerT(_state, getters) {
    return Number.parseInt(getters.getProject?.price_per_t)
  },
  getLabel(state) {
    return state.label
  },
  getSubject(state) {
    return state.subject
  },
  getObjectType(state) {
    return state.object_type
  },
  isOrderValid(state, getters) {
    const subject = state.subject
    const label = state.label
    const price = getters.calculatedPrice
    const project_id = state.project_id
    const co2_amount_in_kg = state.co2_amount_in_kg

    return subject.length > 0 && label.length > 0 && price > 0 && project_id.length > 0 && co2_amount_in_kg.length > 0
  }
}
