import { FOOTPRINT_COMPENSATION_CALCULATOR_STRING } from '~/constants'
import { ConvertDataMixin } from '~/mixins/convert-data-mixin.js'
import { RoutesMixin } from '~/mixins/routes-mixin.js'

export var ParamCompensationObjectMixin = {
  mixins: [ConvertDataMixin, RoutesMixin],
  computed: {
    compensationObjectParam() {
      return this.$router.currentRoute.query?.coid || FOOTPRINT_COMPENSATION_CALCULATOR_STRING
    },
    compensationObject() {
      const data = this.compensationObjectParam.split('-')
      const type = data[0]
      const id = data[1]
      if (type == undefined || id == undefined) {
        return
      }
      const calculatorData = this.$store.getters['store__api__routes/getCalculatorData']
      return calculatorData[type]?.find(candidate => candidate.id == id)
    }
  }
}
