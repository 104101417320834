import { createOrderWithoutPaymentMutation } from '~/plugins/graphql'

const logClass = '[StoreApiOrderCreate]'

export const state = () => ({
  errorMessages: [],
  order: {
    trackingId: undefined
  },
  pending: false,
  success: undefined
})

export const mutations = {
  mutateAddErrorMessage(state, data) {
    state.errorMessages.push(data)
  },
  mutateOrder(state, data) {
    state.order = data
  },
  mutatePending(state, data) {
    state.success = undefined
    state.pending = data
  },
  mutateSuccess(state, data) {
    state.pending = false
    state.success = data
  }
}

export const actions = {
  async create(vuexContext, data) {
    vuexContext.commit('mutatePending', true)

    const store = this.app.store
    const isLoggedIn = store.getters['store__app__user/isLoggedIn']
    const user = store.getters['store__app__user/getUser']

    let customer
    if (isLoggedIn && user) {
      customer = store.getters['store__app__customer/getCustomer']
    }

    const orderInput = aggregateOrderData(data.order, customer)
    triggerMutation(orderInput, this.app.apolloProvider.defaultClient, vuexContext, this)
  }
}

export const getters = {
  getErrorMessages(state) {
    return state.errorMessages
  },
  getOrder(state) {
    return state.order
  },
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}

// private methods

const aggregateOrderData = (order, customer) => {
  return {
    projectId: Number.parseInt(order.projectId),
    co2Amount: order.co2Amount,
    price: order.price,
    label: order.label,
    subject: order.subject,
    objectType: order.object_type,
    customerId: customer?.id ? Number.parseInt(customer.id) : undefined
  }
}

const triggerMutation = (orderInput, apolloClient, vuexContext, thisBinding) => {
  apolloClient
    .mutate({
      mutation: createOrderWithoutPaymentMutation,
      variables: {
        orderInput: orderInput
      }
    })
    .then(async response => {
      const errors = response.data.errors
      if (errors) {
        errors.forEach(error => vuexContext.commit('mutateAddErrorMessage', error.message))
        vuexContext.commit('mutateSuccess', false)
      } else {
        vuexContext.commit('mutateOrder', { trackingId: response?.data?.createOrderWithoutPayment?.order?.trackingId })
        vuexContext.commit('mutateSuccess', true)
        thisBinding.app.store.dispatch('store__app__order-create-without-payment/clearOrderData', {})
      }
    })
    .catch(async error => {
      let errorMessage
      if (error && error.message && error.message.includes('send_order')) {
        errorMessage = error.message
      } else {
        console.warn(logClass, '[triggerMutation]', 'error:', error)
        errorMessage = 'sendOrderFailedGeneric'
        thisBinding.$sentry.captureException(error)
      }
      vuexContext.commit('mutateAddErrorMessage', errorMessage)
      vuexContext.commit('mutateSuccess', false)
    })
}
