import { BUSINESS_CUSTOMER_STRING } from '~/constants'

export var ParamCustomerTypeSlugMixin = {
  async validate(_context) {
    // return !!route(context.$router.currentRoute.path)
    return true
  },
  computed: {
    customerType() {
      if (this.$router.currentRoute.params.customerTypeSlug == BUSINESS_CUSTOMER_STRING) {
        return 'businessCustomer'
      } else {
        return 'privateCustomer'
      }
    }
  }
}
