const logClass = '[ApolloErrorHandler]'

const notifyUser = (nuxtContext, error) => {
  nuxtContext.app.store.dispatch('store__app/setAppStatusErrorMessage', {
    message: error?.message || nuxtContext.app.i18n.t('common.errors.genericErrorMessage')
  })
}

const errorHandlers = {
  InvalidTokenError: async (nuxtContext, error) => {
    console.debug(logClass, '[InvalidTokenError]:', `Token invalid (message: ${error.message}), perform expireSession`)
    await nuxtContext.app.store.dispatch('store__app__user/expireSession')
  },
  GraphQLError: (nuxtContext, error) => {
    if (error.code === 'INVALID_TOKEN') {
      InvalidTokenError(nuxtContext, error)
    } else {
      let errorData = [logClass, '[GraphQLError]:', `Message: ${error.message}`, `Path: ${error.path}`]
      let graphqlErrors = error.extensions.graphqlErrors
      if (graphqlErrors) {
        graphqlErrors.forEach(graphqlError => {
          errorData.push(`Detail: ${graphqlError.message}`)
          graphqlError.locations.forEach(location => {
            errorData.push(`Location: [line: ${location.line}, column: ${location.column}]`)
          })
        })
      }
      console.error(errorData.join('\n'), '\nError:', error)
      notifyUser(nuxtContext)
      nuxtContext.$sentry.setContext('errorData', { errorData })
      nuxtContext.$sentry.captureException(error)
    }
  }
}

export default async ({ graphQLErrors, networkError, _operation, _forward }, nuxtContext) => {
  if (graphQLErrors) {
    graphQLErrors.map(error => {
      console.log(error)
      return (errorHandlers[error.code] || errorHandlers.GraphQLError)(nuxtContext, error)
    })
  }

  if (networkError) {
    const errorMessage = networkError.message
    if (errorMessage.includes('Store reset while query was in flight (not completed in link chain)')) {
      console.debug(logClass, '[NetworkError]:', `${networkError}, hard reset cache`)
      try {
        await nuxtContext.app.apolloProvider.defaultClient.cache.reset()
      } catch (error) {
        console.error(logClass, '[NetworkError]:', `Error on hard reset of cache: ${error}`)
        nuxtContext.$sentry.captureException(error)
      }
    } else if (errorMessage.includes('Invariant Violation: 16 ')) {
      console.debug(logClass, '[NetworkError]:', 'Debug output for not reproducible error:', `${networkError}`)
      nuxtContext.$sentry.captureException(networkError)
    } else {
      console.error(logClass, '[NetworkError]:', `${networkError}`)
      notifyUser(nuxtContext)
      nuxtContext.$sentry.captureException(networkError)
    }
  }
}
