import { languageCodeByPath } from '~/helpers/translation-helper'

export default async function (context) {
  const route = context.route
  const languageCode = languageCodeByPath(route.path)
  const isLoggedIn = context.store.getters['store__app__user/isLoggedIn']

  if (!isLoggedIn) {
    return context.redirect({ name: `index___${languageCode}` })
  }
}
