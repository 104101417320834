import gql from 'graphql-tag'

export const createOrderMutation = gql`
  mutation createOrder($orderInput: OrderInput!, $addressInput: AddressInput!) {
    createOrder(input: { orderInput: $orderInput, addressInput: $addressInput }) {
      order {
        trackingId
      }
      errors
    }
  }
`

export const createOrderWithoutPaymentMutation = gql`
  mutation createOrderWithoutPayment($orderInput: OrderWithoutPaymentInput!) {
    createOrderWithoutPayment(input: { orderInput: $orderInput }) {
      order {
        trackingId
      }
      errors
    }
  }
`
