import { BUSINESS_CUSTOMER_STRING, PRIVATE_CUSTOMER_STRING } from '~/constants'
import { languageCodeByPath } from '~/helpers/translation-helper'

export default {
  getCustomerType: (state, getters) => path =>
    state.rootSlugs[languageCodeByPath(path)][getters.customerTypeSlugForPath(path)],

  getCustomerTypeSlugDefault: state => languageCode =>
    Object.entries(state.rootSlugs[languageCode]).find(entry => entry[1] == PRIVATE_CUSTOMER_STRING)[0],

  customerTypeSlugForPath: _ => path => {
    const customerTypeSlug = path.split('/')[2]
    if (PRIVATE_CUSTOMER_STRING === customerTypeSlug || BUSINESS_CUSTOMER_STRING === customerTypeSlug) {
      return customerTypeSlug
    }
  },

  customerTypeSlugForPathOrDefault: (_store, getters) => path =>
    getters.customerTypeSlugForPath(path) || getters.getCustomerTypeSlugDefault(languageCodeByPath(path)),

  getCustomerTypePath: (_store, getters) => (name, path, context) => {
    const params = { customerTypeSlug: getters.customerTypeSlugForPathOrDefault(path) }
    return getters.getLocalizedPath(`customerTypeSlug-${name}`, params, context)
  }
}
