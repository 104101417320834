import Calculator from '~/components/calculator/calculator.vue'
import {
  BUSINESS_CUSTOMER_STRING,
  CALCULATOR_STRING,
  CALCULATOR_ROOT_STRING,
  ENGLISH_CONTACT_PAGE_ID,
  ENGLISH_DATAPROTECTION_PAGE_ID,
  FOOTPRINT_CALCULATOR_BASE_ROUTE_ID,
  GERMAN_CONTACT_PAGE_ID,
  GERMAN_DATAPROTECTION_PAGE_ID,
  PRIVATE_CUSTOMER_STRING
} from '~/constants'
import { allowsInput } from '~/helpers/calculator-item-helper'
import { customerTypeForPathOrDefault, customerTypeSlugForPathOrDefault } from '~/helpers/routes-helper'
import { slugifyEvent, slugifyPage } from '~/helpers/slugify-helper'
import { translationByPath } from '~/helpers/translation-helper'
import CustomerTypeSlugGeneric from '~/pages/_customerTypeSlug/_.vue'

import * as gettersRoutingHelpers from './store__app__routes/'

export const state = () => ({
  generatedCmsRoutesData: [],
  generatedCalculatorRoutesData: [],
  rootSlugs: {}
})

export const mutations = {
  mutateGeneratedCmsRoutesData(state, data) {
    state.generatedCmsRoutesData = data.routes
  },
  mutateGeneratedCalculatorRoutesData(state, data) {
    state.generatedCalculatorRoutesData = data.routes
  },
  mutateRootSlugs(state, data) {
    state.rootSlugs = data.slugs
  }
}

export const actions = {
  setDefaultRoutesAmount(context, data) {
    context.commit('mutateDefaultRoutesAmount', data)
  },
  generateCmsRoutes(context, data) {
    const rootSlugs = {}
    let routes = []
    data.pages.forEach(page => {
      page.translations.forEach(translation => {
        const languageCode = translation.languages_code.code.substring(0, 2)
        const languageRoute = {
          path: `/${languageCode}`,
          redirect: `/${languageCode}/`
        }

        if (!page.parent_page?.id) {
          if (!rootSlugs[languageCode]) {
            rootSlugs[languageCode] = {}
          }
          if (Object.values(rootSlugs[languageCode]).includes(PRIVATE_CUSTOMER_STRING)) {
            if (!Object.values(rootSlugs[languageCode]).includes(BUSINESS_CUSTOMER_STRING)) {
              rootSlugs[languageCode][translation.slug] = BUSINESS_CUSTOMER_STRING
            }
          } else {
            rootSlugs[languageCode][translation.slug] = PRIVATE_CUSTOMER_STRING
          }
          context.commit('mutateRootSlugs', { slugs: rootSlugs })

          createCmsRoute(routes, data, page, languageRoute, translation, this.app.store)
        }
      })
    })
    const routesData = {
      routes: routes.map(route => {
        let tmp = { ...route }
        delete tmp.component
        return tmp
      })
    }
    context.commit('mutateGeneratedCmsRoutesData', routesData)
  },
  generateCalculatorRoutes(context, data) {
    let routes = []
    let rootRoutes = this.app.store.getters['store__app__routes/getRoutes'].filter(
      route => route.props.routeConfig.isRoot
    )
    rootRoutes.forEach(rootRoute => {
      const languageCode = rootRoute.path.split('/')[1]
      const customerRootRoute = rootRoute
      let calculatorRootRoute = createCalculatorRootRoute(languageCode, customerRootRoute, this.app.store)
      routes.push(calculatorRootRoute)
      if (rootRoute.props.routeConfig.customerType == PRIVATE_CUSTOMER_STRING) {
        createCalculatorRoutes(routes, data, calculatorRootRoute, this.app.store)
      }
    })
    const routesData = {
      routes: routes.map(route => {
        let tmp = { ...route }
        delete tmp.component
        return tmp
      })
    }
    context.commit('mutateGeneratedCalculatorRoutesData', routesData)
  }
}

export const getters = {
  getCalculatorRoutes(state) {
    return state.generatedCalculatorRoutesData
  },
  isCalculatorRoute: state => path => {
    return state.generatedCalculatorRoutesData.find(route => route.path == path) ? true : false
  },
  getRoutes(state) {
    return state.generatedCmsRoutesData.concat(state.generatedCalculatorRoutesData)
  },
  getRoute: state => path => {
    return (
      state.generatedCmsRoutesData.find(route => route.path == path) ||
      state.generatedCalculatorRoutesData.find(route => route.path == path)
    )
  },
  ...Object.assign({}, ...Object.values(gettersRoutingHelpers))
}

// cms content route generation

const createCmsRoute = (routes, data, page, parentRoute, translation, store) => {
  const slug = slugifyPage(translation, page)
  const path = `${parentRoute.path}/${slug}`
  const languageCode = translation.languages_code.code.substring(0, 2)
  const name = `pages-${page.id}___${languageCode}`
  const route = {
    name: name,
    path: path,
    component: CustomerTypeSlugGeneric,
    props: {
      routeConfig: {
        elementType: 'pages',
        id: page.id,
        title: translation.title || page.id,
        showInNavMain: page.show_in_nav_main,
        showInFooter: page.show_in_footer,
        isDataProtectionPage: page?.id == GERMAN_DATAPROTECTION_PAGE_ID || page?.id == ENGLISH_DATAPROTECTION_PAGE_ID,
        isContactPage: page?.id == GERMAN_CONTACT_PAGE_ID || page?.id == ENGLISH_CONTACT_PAGE_ID,
        isRoot: !page.parent_page?.id,
        parentRoutePath: parentRoute.path,
        customerTypeSlug: customerTypeSlugForPathOrDefault(path, store),
        customerType: customerTypeForPathOrDefault(path, store),
        routeType: 'cms'
      }
    }
  }
  routes.push(route)
  if (page.sub_pages?.length > 0) {
    page.sub_pages.forEach(subPageId => {
      const subPage = data.pages.find(page => page.id === subPageId.id)
      generateChildRoutes(routes, data, subPage, route, translation.languages_code.code, store)
    })
  }
}

const generateChildRoutes = (routes, data, page, parentRoute, locale, store) => {
  const translation =
    page &&
    page.translations &&
    page.translations.find(translation => {
      return locale === translation.languages_code.code
    })
  if (translation) {
    createCmsRoute(routes, data, page, parentRoute, translation, store)
  }
}

// calculator content route generation

const createCalculatorRoutes = (routes, calculatorData, parentRoute, store) => {
  if (calculatorData.event_types.length > 0) {
    const rootEventTypes = calculatorData.event_types.filter(eventType => !eventType.parent_event_type)

    rootEventTypes.forEach(rootEventType => {
      const translation = translationByPath(parentRoute.path, rootEventType.translations)

      const isFootprintCalculatorPage = rootEventType.id === FOOTPRINT_CALCULATOR_BASE_ROUTE_ID

      if (translation) {
        createEventTypeRoute(
          routes,
          calculatorData,
          rootEventType,
          parentRoute,
          translation,
          store,
          isFootprintCalculatorPage
        )
      }
    })
  } else {
    throw new Error('No event types given for generating calculator routes')
  }
}

const createEventTypeRoute = (
  routes,
  calculatorData,
  eventType,
  parentRoute,
  translation,
  store,
  isFootprintCalculatorPage
) => {
  let route = undefined
  const shouldCreateRoute = eventType.sub_event_types.length > 0 || allowsInput(eventType)
  if (shouldCreateRoute) {
    route = createEventRoute(
      routes,
      calculatorData,
      eventType,
      parentRoute,
      translation,
      store,
      isFootprintCalculatorPage
    )
  } else {
    return
  }

  if (eventType.sub_event_types.length > 0) {
    eventType.sub_event_types.forEach(childEventType => {
      const childEventTypeById = calculatorData.event_types.find(eventType => eventType.id == childEventType.id)
      const eventTypeTranslation = getTranslation(childEventTypeById.translations, route.path)
      if (eventTypeTranslation) {
        createEventTypeRoute(
          routes,
          calculatorData,
          childEventTypeById,
          route,
          eventTypeTranslation,
          store,
          isFootprintCalculatorPage
        )
      }
    })
  } else {
    createInputRoute(routes, calculatorData, eventType, route, store, isFootprintCalculatorPage)
  }
}

const createInputRoute = (routes, calculatorData, eventType, parentRoute, store, isFootprintCalculatorPage) => {
  const calculatorInput = calculatorData.calculator_input.find(
    calculator => calculator.event_type?.id && eventType?.id && calculator.event_type.id == eventType.id
  )
  if (calculatorInput) {
    const calculatorInputTranslation = getTranslation(calculatorInput.translations, parentRoute.path)
    if (calculatorInputTranslation) {
      createEventRoute(
        routes,
        calculatorData,
        calculatorInput,
        parentRoute,
        calculatorInputTranslation,
        store,
        isFootprintCalculatorPage
      )
    }
  } else {
    console.error(`Missing calculator input for event type ${eventType.id}`)
  }
}

const createEventRoute = (
  routes,
  calculatorData,
  eventType,
  parentRoute,
  translation,
  store,
  isFootprintCalculatorPage
) => {
  const slug = slugifyEvent(translation, eventType)
  const path = `${parentRoute.path}/${slug}`
  const isBaseEventType = parentRoute.name.includes(`event_types-${FOOTPRINT_CALCULATOR_BASE_ROUTE_ID}`)

  const route = {
    name: `${eventType.__typename}-${eventType.id}___${translation.languages_code.code.substring(0, 2)}`,
    path: path,
    component: Calculator,
    props: {
      routeConfig: {
        elementType: eventType.__typename,
        id: eventType.id,
        title: translation.label || translation.name || translation.question || eventType.id,
        showInNavMain: false,
        showInFooter: false,
        isRoot: false,
        isBaseEventType: isBaseEventType,
        isFootprintCalculatorPage: isFootprintCalculatorPage,
        parentRoutePath: parentRoute.path,
        customerTypeSlug: customerTypeSlugForPathOrDefault(path, store),
        customerType: customerTypeForPathOrDefault(path, store),
        routeType: CALCULATOR_STRING
      }
    }
  }

  if (!!isBaseEventType && !!isFootprintCalculatorPage) {
    store.dispatch('store__app__footprint__calculator/setFootprintCalculatorStep', route)
  }
  routes.push(route)
  return route
}

const getTranslation = (translations, path) => {
  return translationByPath(path, translations)
}

const createCalculatorRootRoute = (languageCode, parentRoute, store) => {
  const path = parentRoute.path.concat('/', store.$i18n.t('routes.co2Calculator.index'))
  const name = `calculator-${customerTypeForPathOrDefault(path, store)}___${languageCode}`
  return {
    name: name,
    path: path,
    component: Calculator,
    props: {
      routeConfig: {
        elementType: CALCULATOR_ROOT_STRING,
        id: name,
        title: store.$i18n.t('pages.co2Calculator.headline'),
        showInNavMain: false,
        showInFooter: false,
        isRoot: false,
        parentRoutePath: parentRoute.path,
        customerTypeSlug: customerTypeSlugForPathOrDefault(path, store),
        customerType: customerTypeForPathOrDefault(path, store),
        routeType: CALCULATOR_STRING
      }
    }
  }
}
