import { languageCodeByPath } from '~/helpers/translation-helper'

export default async function (context) {
  if (process.server) return

  const store = context.store

  if (!isBusinessCustomerTypeFromStore(store)) {
    const route = context.route
    const languageCode = languageCodeByPath(route.path)
    return context.redirect({ name: `index___${languageCode}` })
  }
}

function isBusinessCustomerTypeFromStore(store) {
  return store.getters['store__app__customer/isBusinessCustomer']
}
