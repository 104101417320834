
import DropdownNavigationBusinessCustomer from '~/components/the-header/dropdown-navigation/customer-type/business-customer'
import { RoutesMixin, TranslationMixin } from '~/mixins'

export default {
  name: 'DropdownNavigation',
  mixins: [RoutesMixin, TranslationMixin],
  components: { DropdownNavigationBusinessCustomer },
  computed: {
    isLoggedIn() {
      return this.$store.getters['store__app__user/isLoggedIn']
    },
    orderCreateWithoutPaymentPath() {
      return this.$store.getters['store__app__routes/orderCreateWithoutPaymentPath'](this)
    },
    user() {
      return this.$store.getters['store__app__user/getUser']
    },
    userAlias() {
      if (this.usersMe) {
        if (this.usersMe?.first_name && this.usersMe?.last_name) {
          return `${this.usersMe.first_name[0]}. ${this.usersMe.last_name}`
        } else {
          return this.user?.email
        }
      }
    },
    usersMe() {
      return this.$store.getters['store__app__user/getUsersMe']
    },
    // TODO: extract in own RoleHandlerClass
    isBusinessCustomerType() {
      return this.$store.getters['store__app__customer/isBusinessCustomer']
    }
  },
  methods: {
    removeFocus(event) {
      const parentElement = event.target.closest('.nav-header__dropdown')
      const hideTimeout = 50
      const blurTimeout = 250

      if (parentElement) {
        window.setTimeout(function () {
          event.target.blur()

          // to deactive css-over style and hide dropdown
          parentElement.classList.add('nav-header__dropdown--js-hide')

          window.setTimeout(function () {
            parentElement.classList.remove('nav-header__dropdown--js-hide')
          }, hideTimeout)
        }, blurTimeout)
      }
    },
    async signOut() {
      await this.$store.dispatch('store__app__user/logoutUser')
      this.$store.dispatch('store__app/setAppStatusSuccessMessage', {
        message: this.$i18n.t(`components.theHeader.flashMessages.signOutSuccess`)
      })
      // TODO: add root path in store routes
      this.$router.push({ name: `index___${this.languageCode}` })
    }
  }
}
