import { DEFAULT_INPUT_VALUE_FOR_CALCULATOR, SINGLE_COMPENSATION_CALCULATOR_STRING } from '~/constants'
import {
  allCmsRoutesAccordingToPath,
  businessCustomerCalculatorRootPath,
  businessCustomerRootPath,
  calculatorRootPathAccordingToPath,
  calculatorRoutes,
  cmsRoutes,
  customerTypeSlugForPathOrDefault,
  isCalculatorRootRoute,
  isCalculatorRoute,
  loginPath,
  orderCompletePath,
  orderCreatePath,
  orderOverviewPath,
  privateCustomerCalculatorRootPath,
  privateCustomerRootPath,
  rootRoutes,
  route,
  sanitizePath
} from '~/helpers/routes-helper'
import { TranslationMixin } from '~/mixins/translation-mixin.js'

export var RoutesMixin = {
  mixins: [TranslationMixin],
  computed: {
    // routes helpers

    calculatorRoutes() {
      return calculatorRoutes(this.languageCode, this.$store)
    },
    cmsRoutes() {
      return cmsRoutes(this.languageCode, this.$store)
    },
    rootRoutes() {
      return rootRoutes(this.languageCode, this.$store)
    },

    // cms path helpers

    privateCustomerRootPath() {
      return privateCustomerRootPath(this.languageCode, this.$store)
    },
    businessCustomerRootPath() {
      return businessCustomerRootPath(this.languageCode, this.$store)
    },
    privateCustomerCalculatorRootPath() {
      return privateCustomerCalculatorRootPath(this.languageCode, this.$store)
    },
    businessCustomerCalculatorRootPath() {
      return businessCustomerCalculatorRootPath(this.languageCode, this.$store)
    },
    isBusinessCustomerPath() {
      return this.$store.getters['store__app/isBusinessCustomerPath']
    },

    // application path helpers

    loginPath() {
      if (this.$i18n.locale) {
        return loginPath(this, this.$store, sanitizePath(this.$router.currentRoute.path))
      } else {
        return ''
      }
    },
    orderCompletePath() {
      if (this.$i18n.locale) {
        return orderCompletePath(this, this.$store, sanitizePath(this.$router.currentRoute.path), this.trackingId)
      } else {
        return ''
      }
    },
    orderCreatePath() {
      if (this.$i18n.locale) {
        return orderCreatePath(this, this.$store, sanitizePath(this.$router.currentRoute.path))
      } else {
        return ''
      }
    },
    orderOverviewPath() {
      if (this.$i18n.locale) {
        return orderOverviewPath(this, this.$store, sanitizePath(this.$router.currentRoute.path))
      } else {
        return ''
      }
    }
  },
  methods: {
    route(path) {
      return route(path, this.$store)
    },
    dataProtectionRouteAccordingToPath(path) {
      return this.allCmsRoutesAccordingToPath(path).find(route => route.props.routeConfig.isDataProtectionPage)
    },
    dataContactRouteAccordingToPath(path) {
      return this.allCmsRoutesAccordingToPath(path).find(route => route.props.routeConfig.isContactPage)
    },
    calculatorRootPathAccordingToPath(path) {
      return calculatorRootPathAccordingToPath(path, this.languageCode, this.$store)
    },
    allCmsRoutesAccordingToPath(path) {
      return allCmsRoutesAccordingToPath(path, this.languageCode, this.$store)
    },
    isBusinessCustomerRootPath(path) {
      return this.businessCustomerRootPath == path
    },
    isPrivateCustomerRootPath(path) {
      return this.privateCustomerRootPath == path
    },
    isCalculatorRoute(path) {
      return isCalculatorRoute(path, this.languageCode, this.$store)
    },
    isCalculatorRootRoute(path) {
      return isCalculatorRootRoute(path, this.languageCode, this.$store)
    },
    customerTypeSlugForPathOrDefault(path) {
      return customerTypeSlugForPathOrDefault(path, this.$store)
    },
    pathForPage(page) {
      if (page && page.id) {
        const routeName = `${page.__typename}-${page.id}___${this.languageCode}`
        return this.cmsRoutes.find(route => route.name == routeName)?.path || ''
      } else {
        return ''
      }
    },
    projectSelectionLocation(
      calculation_input,
      inputValue = DEFAULT_INPUT_VALUE_FOR_CALCULATOR,
      type = SINGLE_COMPENSATION_CALCULATOR_STRING
    ) {
      let calculatedCo2Amount

      if (type === SINGLE_COMPENSATION_CALCULATOR_STRING) {
        calculatedCo2Amount = this.convertFloatToString(inputValue * calculation_input.factor, 6)
      } else {
        calculatedCo2Amount = this.convertFloatToString(
          this.$store.getters['store__app__footprint__calculator/calculatedCo2Amount'],
          6
        )
      }
      return this.localeLocation({
        name: 'customerTypeSlug-projectSelection',
        params: {
          customerTypeSlug: this.customerTypeSlugForPathOrDefault(this.$router.currentRoute.path)
        },
        query: {
          t: calculatedCo2Amount,
          coid: `${calculation_input.__typename}-${calculation_input.id}`,
          type: type
        }
      })
    }
  }
}
