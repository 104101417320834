const middleware = {}

middleware['checkAuth'] = require('../middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['index'] = require('../middleware/index.js')
middleware['index'] = middleware['index'].default || middleware['index']

middleware['initFootprintCalculatorSteps'] = require('../middleware/initFootprintCalculatorSteps.js')
middleware['initFootprintCalculatorSteps'] = middleware['initFootprintCalculatorSteps'].default || middleware['initFootprintCalculatorSteps']

middleware['isBusinessCustomerRoute'] = require('../middleware/isBusinessCustomerRoute.js')
middleware['isBusinessCustomerRoute'] = middleware['isBusinessCustomerRoute'].default || middleware['isBusinessCustomerRoute']

middleware['isBusinessCustomerType'] = require('../middleware/isBusinessCustomerType.js')
middleware['isBusinessCustomerType'] = middleware['isBusinessCustomerType'].default || middleware['isBusinessCustomerType']

middleware['isLoggedIn'] = require('../middleware/isLoggedIn.js')
middleware['isLoggedIn'] = middleware['isLoggedIn'].default || middleware['isLoggedIn']

middleware['preventBusinessCalculatorRoute'] = require('../middleware/preventBusinessCalculatorRoute.js')
middleware['preventBusinessCalculatorRoute'] = middleware['preventBusinessCalculatorRoute'].default || middleware['preventBusinessCalculatorRoute']

middleware['preventForeignLocaleRoutes'] = require('../middleware/preventForeignLocaleRoutes.js')
middleware['preventForeignLocaleRoutes'] = middleware['preventForeignLocaleRoutes'].default || middleware['preventForeignLocaleRoutes']

middleware['preventPrivateRoutesButNotRegistration'] = require('../middleware/preventPrivateRoutesButNotRegistration.js')
middleware['preventPrivateRoutesButNotRegistration'] = middleware['preventPrivateRoutesButNotRegistration'].default || middleware['preventPrivateRoutesButNotRegistration']

middleware['refreshSessionToken'] = require('../middleware/refreshSessionToken.js')
middleware['refreshSessionToken'] = middleware['refreshSessionToken'].default || middleware['refreshSessionToken']

middleware['setDefaultLocale'] = require('../middleware/setDefaultLocale.js')
middleware['setDefaultLocale'] = middleware['setDefaultLocale'].default || middleware['setDefaultLocale']

middleware['setLayoutForDevice'] = require('../middleware/setLayoutForDevice.js')
middleware['setLayoutForDevice'] = middleware['setLayoutForDevice'].default || middleware['setLayoutForDevice']

export default middleware
