
export default {
  name: 'TheHeroStageImage',
  props: {
    src: {
      type: String
    },
    item: {
      type: Object,
      media: {
        type: Object,
        required: true,
        filename_disk: {
          type: String,
          required: true
        }
      }
    }
  },
  computed: {
    headline() {
      return this.$store.getters['store__app/getPageHeadline']
    }
  }
}
