import gql from 'graphql-tag'

export const rootTypeDefs = gql`
  ### ROOT TYPES ###
  type Query {
    ping: String
  }

  type Mutation {
    ### BACKOFFICE ###
    authUser(email: String!, password: String!): Auth
    ### CORE APP ###
    createOrder(input: InputType!): OrderType
  }

  ### BACKOFFICE ###

  type Auth {
    access_token: String
    expires: Int
    refresh_token: String
  }

  ### CORE APP ###

  input InputType {
    orderInput: OrderInput!
    addressInput: AddressInput!
  }

  input OrderInput {
    project_id: ID!
    co2_amount: Float!
    price: Float!
    label: String!
    subject: String!
    payment_informations: String!
  }

  input AddressInput {
    firstname: String!
    lastname: String!
    street: String!
    housenumber: String!
    zipcode: String!
    city: String!
    email: String!
  }

  type OrderType {
    project_id: ID!
    co2_amount: Float!
    price: Float!
    label: String!
    subject: String!
  }
`
