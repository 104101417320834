
import AppNavItem from '~/components/app/app__nav-item/app__nav-item.vue'
import { RoutesMixin } from '~/mixins'

export default {
  name: 'Footer',
  components: { AppNavItem },
  mixins: [RoutesMixin],
  data() {
    return {
      linkedRoutes: []
    }
  },
  created() {
    this.linkedRoutes = this.linkedRoutesBy(this.$router.currentRoute.path)
  },
  watch: {
    $route(to, _from) {
      this.linkedRoutes = this.linkedRoutesBy(to.path)
    }
  },
  computed: {
    isUserCentricsDefined() {
      return typeof UC_UI !== 'undefined'
    }
  },
  methods: {
    linkedRoutesBy(path) {
      return this.allCmsRoutesAccordingToPath(path).filter(route => route.props.routeConfig.showInFooter)
    }
  }
}
