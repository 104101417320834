import gql from 'graphql-tag'

import { translationsFilter } from '../filters'

export const AllowedObjectTypesForLabelGenerationQuery = gql`
  query allowedObjectTypesForLabelGeneration {
    allowedObjectTypesForLabelGeneration
  }
`

export const CompensationObjectTypesQuery = gql`
  query compensationObjectTypesQuery {
    validObjectCompensationTypes
  }
`

export const myOrdersCountQuery = gql`
  query myOrdersCountQuery {
    orders_aggregated(limit: -1, filter: { customer_id: { directus_user_id: { id: { _eq: "$CURRENT_USER" } } } }) {
      count {
        id
      }
    }
  }
`

export function orderQuery() {
  return ordersQuery('$trackingId: String!, $locale: String!', ['tracking_id: { _eq: $trackingId }'])
}

export function getMyOrdersQuery(pageNumber) {
  return ordersQuery(
    '$locale: String!',
    ['customer_id: { directus_user_id: { id: { _eq: "$CURRENT_USER" } } }'],
    pageNumber
  )
}

export function ordersQuery(queryParams, filterElements, pageNumber) {
  return gql`
    ${queryParams ? `query ordersByQueryParamsAndFilters (${queryParams}) {` : 'query ordersByFilters {'}
      orders(page: ${pageNumber || 1}, filter: { ${filterElements.join()} }, sort: ["-date_created"]) {
        certificate_pdf_path
        co2_amount
        date_created
        id
        label
        object_type
        price
        project_id {
          id
          sort
          ${translationsFilter()} {
            languages_code { code }
            name
            short_teaser
          }
          image {
            id
            title
            filename_disk
          }
          page {
            id
          }
          project_stage {
            media {
              id
              filename_disk
              title
              type
            }
            image_triple {
              id
              filename_disk
              title
              type
            }
          }
        }
        state
        subject
        tracking_id
      }
    }
  `
}
