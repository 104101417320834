
import { GoogleTagManagerIntegrationMixin, LayoutMixin, RoutesMixin } from '~/mixins'

export default {
  name: 'ProgressiveWebApp',
  mixins: [GoogleTagManagerIntegrationMixin, LayoutMixin, RoutesMixin],
  data() {
    return {
      currentPageRootClass: undefined
    }
  },
  watch: {
    $route(to) {
      this.setCurrentPageRootClass(to.path)
      this.addGtmScriptToBody()
    }
  },
  methods: {
    setCurrentPageRootClass(path) {
      this.currentPageRootClass = this.getCurrentPageRootCssClass(path)
    }
  },
  created() {
    this.setCurrentPageRootClass(this.$router.currentRoute.path)
  }
}
