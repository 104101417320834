
import AppNavItem from '~/components/app/app__nav-item/app__nav-item.vue'
import { RoutesMixin, SessionMixin } from '~/mixins'

export default {
  name: 'TheMainNav',
  components: { AppNavItem },
  mixins: [RoutesMixin, SessionMixin],
  computed: {
    showNavMain() {
      return this.$store.getters['store__app/isShowHeaderNavMain']
    },
    businessCustomerMyCo2untPath() {
      if (this.isLoggedIn) {
        return this.$store.getters['store__app__routes/orderOverviewPath'](this.businessCustomerRootPath, this)
      } else {
        return this.$store.getters['store__app__routes/loginPath'](this.businessCustomerRootPath, this)
      }
    },
    privateCustomerMyCo2untPath() {
      if (this.isLoggedIn) {
        return this.$store.getters['store__app__routes/orderOverviewPath'](this.privateCustomerRootPath, this)
      } else {
        return this.$store.getters['store__app__routes/loginPath'](this.privateCustomerRootPath, this)
      }
    }
  },
  watch: {
    showNavMain() {
      if (this.showNavMain) {
        document.body.classList.add('body--fixed')
      } else {
        document.body.classList.remove('body--fixed')
      }
    }
  },
  methods: {
    closeNavMain() {
      this.$store.dispatch('store__app/setShowHeaderNavMain', { data: false })
    },
    cssMainFrameBusinessCustomer() {
      if (this.$router.currentRoute.path.startsWith(this.businessCustomerRootPath))
        return 'nav-grid__item--active nav-grid__item--inversed'
      else return ''
    },
    cssMainFramePrivateCustomer() {
      if (!this.$router.currentRoute.path.startsWith(this.businessCustomerRootPath))
        return 'nav-grid__item--active nav-grid__item--inversed'
      else return ''
    },
    linkedRoutes(path) {
      return this.allCmsRoutesAccordingToPath(path).filter(route => route.props.routeConfig.showInNavMain)
    }
  }
}
