
import { isLanguageRootPath } from '~/helpers/routes-helper'
import { RoutesMixin, TranslationMixin } from '~/mixins'
import { heroStagesQuery } from '~/plugins/graphql'

export default {
  name: 'TheHeroStage',
  mixins: [RoutesMixin, TranslationMixin],
  async fetch() {
    await this.$apollo.queries.heroStage.observer.result()
  },
  apollo: {
    heroStage: {
      deep: true,
      query: heroStagesQuery,
      update: data => ({ ...data.hero_stages[0] }),
      variables() {
        return {
          locale: this.locale
        }
      }
    }
  },
  data() {
    return {
      heroStage: undefined,
      showHeroStage: undefined
    }
  },
  watch: {
    $route(to) {
      this.showHeroStage = isLanguageRootPath(to.path) || !!this.route(to.path)?.props?.routeConfig?.isRoot
    }
  },
  created() {
    const path = this.$router.currentRoute.path
    this.showHeroStage = isLanguageRootPath(path) || !!this.route(path)?.props?.routeConfig?.isRoot
  }
}
