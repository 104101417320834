const logClass = '[StoreAppLogin]'

export const state = () => ({})

export const mutations = {
  mutateState(state, data) {
    state[data.key] = data.value
  }
}

export const actions = {
  setInputValue(vuexContext, data) {
    const logMethod = '[setInputValue]'
    const valid_data = ['email', 'password']
    if (valid_data.includes(data.key)) {
      vuexContext.commit('mutateState', data)
    } else {
      console.error(logClass, logMethod, 'unknown key for store:', data.key)
    }
  }
}

export const getters = {
  getInputValue: state => key => {
    return state[key]
  }
}
