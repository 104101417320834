import Vue from 'vue'

import { GetterTree, ActionTree, MutationTree } from 'vuex'

// TODO: Define correct types here!
const autoHideAppStatusMessages = (vuexContext: any, data: any, index: any) => {
  if (data.displayDuration >= 0) {
    if (data.autoHideTimeoutID) {
      clearTimeout(data.autoHideTimeoutID)
    }
    setTimeout(function () {
      if (data.decreaseAutoHideCounter) {
        vuexContext.commit('mutateDecreaseAppStatusMessagesDuration', data)
      }
      autoHideAppStatusMessages(vuexContext, data, index)
    }, 1000)
  } else {
    vuexContext.dispatch('removeAppStatusMessages', index)
  }
}

export const state = () => ({
  showTheButtonScrolldown: false,
  showHeaderNavMain: false,

  currentLayoutForDevice: undefined,

  pageHeadline: undefined,

  appStatusMessagesDefaultDisplayDuration: 10,
  // TODO: Define correct types here!
  appStatusMessages: {} as any,

  isBusinessCustomerPath: false,

  roleCustomer: undefined
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  mutateAppStatusMessages(state, data) {
    if (!data.displayDuration) {
      data.displayDuration = state.appStatusMessagesDefaultDisplayDuration
    }
    data.decreaseAutoHideCounter = true

    let newDataIndex = new Date().getTime()

    Vue.set(state.appStatusMessages, newDataIndex, data)
  },
  mutateCurrentLayoutForDevice(state, data) {
    state.currentLayoutForDevice = data
  },
  mutateDecreaseAppStatusMessagesDuration(_state, data) {
    data.displayDuration--
  },
  mutateIsBusinessCustomerPath(state, data) {
    state.isBusinessCustomerPath = data
  },
  mutatePageHeadline(state, data) {
    state.pageHeadline = data
  },
  mutatePauseAppStatusMessagesDuration(state, index) {
    Vue.set(state.appStatusMessages[index], 'decreaseAutoHideCounter', false)
  },
  mutateRemoveAppStatusMessages(state, index) {
    Vue.delete(state.appStatusMessages, index)
  },
  mutateRoleCustomer(state, data) {
    state.roleCustomer = data
  },
  mutateShowHeaderNavMain(state, data) {
    state.showHeaderNavMain = data.flag
  },
  mutateShowTheButtonScrolldown(state, data) {
    state.showTheButtonScrolldown = data.flag
  },
  mutateStartAppStatusMessagesDuration(state, index) {
    if (state.appStatusMessages[index]) {
      Vue.set(state.appStatusMessages[index], 'decreaseAutoHideCounter', true)
    }
  }
}

export const actions: ActionTree<RootState, RootState> = {
  // TODO: create new middleware for initOrder and initCustomer if this will be persisted in the state
  pauseAppStatusMessagesDuration(vuexContext, index) {
    vuexContext.commit('mutatePauseAppStatusMessagesDuration', index)
  },
  removeAppStatusMessages(vuexContext, index) {
    vuexContext.commit('mutateRemoveAppStatusMessages', index)
  },
  setAppStatusMessage(vuexContext, data) {
    data.type || (data.type = 'info')

    vuexContext.commit('mutateAppStatusMessages', data)
    autoHideAppStatusMessages(vuexContext, data, vuexContext.getters.getLastAppStatusMessageIndex)
  },
  setAppStatusErrorMessage(vuexContext, data) {
    data.type = 'error'
    vuexContext.dispatch('setAppStatusMessage', data)
  },
  setAppStatusSuccessMessage(vuexContext, data) {
    data.type = 'success'
    vuexContext.dispatch('setAppStatusMessage', data)
  },
  setAppStatusWarningMessage(vuexContext, data) {
    data.type = 'warning'
    vuexContext.dispatch('setAppStatusMessage', data)
  },
  setCurrentLayoutForDevice(vuexContext, data) {
    vuexContext.commit('mutateCurrentLayoutForDevice', data)
  },
  setIsBusinessCustomerPath(vuexContext, data) {
    vuexContext.commit('mutateIsBusinessCustomerPath', data)
  },
  setPageHeadline(vuexContext, data) {
    vuexContext.commit('mutatePageHeadline', data)
  },
  setRoleCustomer(vuexContext, data) {
    vuexContext.commit('mutateRoleCustomer', data)
  },
  setShowHeaderNavMain(vuexContext, data) {
    vuexContext.commit('mutateShowHeaderNavMain', data)
  },
  setShowTheButtonScrolldown(vuexContext, data) {
    vuexContext.commit('mutateShowTheButtonScrolldown', data)
  },
  startAppStatusMessagesDuration(vuexContext, index) {
    vuexContext.commit('mutateStartAppStatusMessagesDuration', index)
  }
}

export const getters: GetterTree<RootState, RootState> = {
  getAppStatusMessages(state) {
    return state.appStatusMessages
  },
  getAppStatusMessagesDefaultDisplayDuration(state) {
    return state.appStatusMessagesDefaultDisplayDuration
  },
  getCurrentLayoutForDevice(state) {
    return state.currentLayoutForDevice
  },
  getLastAppStatusMessageIndex(state) {
    return Object.keys(state.appStatusMessages).pop()
  },
  getPageHeadline(state) {
    return state.pageHeadline
  },
  getRoleCustomer(state) {
    return state.roleCustomer
  },
  isBusinessCustomerPath(state) {
    return state.isBusinessCustomerPath
  },
  isShowHeaderNavMain(state) {
    return state.showHeaderNavMain
  },
  isShowTheButtonScrolldown(state) {
    return state.showTheButtonScrolldown
  }
}
