
export default {
  name: 'AppNavItem',
  props: {
    attrTo: {
      type: String
    },
    attrToObject: {
      type: Object,
      name: {
        type: String
      },
      params: {
        type: String
      },
      query: {
        type: String
      }
    },
    attrClass: {
      type: String
    }
  },
  computed: {
    activeClass() {
      return 'router-link-active'
    }
  },
  methods: {
    closeNavMain(navigate, event) {
      this.$store.dispatch('store__app/setShowHeaderNavMain', { data: false })
      navigate(event)
    },
    checkIsActive(isActive) {
      return isActive && this.activeClass
    }
  }
}
