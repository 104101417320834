export function translationByPath(path, translations) {
  if (path === undefined) throw new Error('missing path')
  const languageCode = languageCodeByPath(path)
  return translations?.find(translation => translation.languages_code.code.substring(0, 2) == languageCode)
}

export function languageCodeByPath(path) {
  let languageCode = ''
  if (path) {
    languageCode = languageSegmentInPath(path)
  }
  if (!languageCode) {
    languageCode = 'de'
  }
  return languageCode
}

export function languageSegmentInPath(path) {
  if (path) {
    const segment = path.substring(0, 1) == '/' ? path.split('/')[1] : path.split('/')[0]
    if (segment == 'de' || segment == 'en') {
      return segment
    }
  }
}

export function localeByLanguageCode(languageCode) {
  if (languageCode === undefined) throw new Error('missing languageCode')

  const localeHash = {
    de: 'de-DE',
    en: 'en-US'
  }
  return localeHash[languageCode] || localeHash['de']
}
