import { RoutesMixin } from '~/mixins/routes-mixin.js'

export var ProjectMixin = {
  mixins: [RoutesMixin],
  computed: {
    projectPath() {
      return this.pathForPage(this.item.page)
    }
  }
}
