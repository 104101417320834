import { logoutUserMutation } from '~/plugins/graphql'

const logClass = '[StoreApiLogout]'

export const state = () => ({
  pending: false,
  success: undefined
})

export const mutations = {
  mutatePending(state, data) {
    state.pending = data.flag
  },
  mutateSuccess(state, data) {
    state.success = data.flag
  }
}

export const actions = {
  async logoutUser(vuexContext) {
    const logMethod = '[logout]'

    vuexContext.commit('mutatePending', { flag: true })
    vuexContext.commit('mutateSuccess', { success: undefined })

    const refreshToken = this.app.store.getters['store__app__user/getUser'].refreshToken

    await this.app.apolloProvider.defaultClient
      .mutate({
        mutation: logoutUserMutation,
        variables: { refresh_token: refreshToken }
      })
      .then(_response => {
        vuexContext.commit('mutateSuccess', { flag: true })
      })
      .catch(error => {
        let errorMessage

        vuexContext.commit('mutateSuccess', { flag: false })

        switch (error.message) {
          case '"refresh_token" is required in either the JSON payload or Cookie':
            errorMessage = 'logoutFailedEmptyRefreshToken'
            break
          case '"String cannot represent a non string value: undefined"':
            errorMessage = 'loginFailedUndefinedRefreshToken'
            break
          default:
            errorMessage = 'logoutFailedGeneric'
            this.$sentry.captureException(error)
        }

        console.error(logClass, logMethod, ' error: ', error, ' errorMessage: ', errorMessage)
      })

    vuexContext.commit('mutatePending', { flag: false })
  }
}

export const getters = {
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}
