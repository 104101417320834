import { ConvertDataMixin } from '~/mixins/convert-data-mixin.js'

export var ParamCompensationAmountMixin = {
  mixins: [ConvertDataMixin],
  computed: {
    compensationAmountParam() {
      return this.$router.currentRoute.query.t || '0'
    },
    compensationAmountAsFloat() {
      return this.convertStringToFloat(this.compensationAmountParam)
    },
    compensationAmountAsString() {
      const amount_in_t = this.compensationAmountAsFloat
      return this.convertFloatToWeightString(amount_in_t)
    }
  }
}
