import { RoutesMixin } from '~/mixins/routes-mixin.js'

export var PageIdMixin = {
  mixins: [RoutesMixin],
  computed: {
    pageId() {
      const routeObject = this.route(this.$router.currentRoute.path, this.$store)
      return this.$store.getters['store__app__routes/getCmsPageIdFromRoute'](routeObject)
    }
  }
}
