import { render, staticRenderFns } from "./app__status-box.vue?vue&type=template&id=c7900934&lang=pug&"
import script from "./app__status-box.vue?vue&type=script&lang=js&"
export * from "./app__status-box.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppStatusBoxItem: require('/co2unt/services/customer-app/components/app/app__status-box/app__status-box__item.vue').default})
