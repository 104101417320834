import { myOrdersCountQuery } from '~/plugins/graphql/queries'

const logClass = '[Store::Api::Order::Count]'

export const state = () => ({
  errorMessages: [],
  pending: false,
  success: undefined
})

export const mutations = {
  mutateAddErrorMessage(state, data) {
    state.errorMessages.push(data)
  },
  mutatePending(state, data) {
    state.success = undefined
    state.pending = data
  },
  mutateSuccess(state, data) {
    state.pending = false
    state.success = data
  }
}

export const actions = {
  async call(vuexContext) {
    const logMethod = '[call]'

    vuexContext.commit('mutatePending', { flag: true })
    vuexContext.commit('mutateSuccess', { success: undefined })

    await this.app.apolloProvider.defaultClient
      .query({
        query: myOrdersCountQuery
      })
      .then(response => {
        vuexContext.commit('mutateSuccess', { flag: true })

        const count = response.data.orders_aggregated[0].count.id
        if (count) {
          this.app.store.commit('store__app__order/mutateCount', count)
        }
      })
      .catch(error => {
        vuexContext.commit('mutateSuccess', { flag: false })
        console.error(logClass, logMethod, error)
      })
  }
}
