
import { route } from '~/helpers/routes-helper'
import { FlashMessageMixin, PageIdMixin, ParamCustomerTypeSlugMixin, RoutesMixin } from '~/mixins'

export default {
  name: 'CustomerTypeSlugGeneric',
  layout: context => context.store.getters['store__app/getCurrentLayoutForDevice'],
  async validate(context) {
    const currentRoute = route(context.route.path, context.app.store)
    return !!currentRoute?.props?.routeConfig?.id
  },
  mixins: [FlashMessageMixin, PageIdMixin, ParamCustomerTypeSlugMixin, RoutesMixin],
  props: {
    routeConfig: {
      type: Object
    }
  },
  computed: {
    shouldRenderCalculator() {
      return this.isCalculatorRoute(this.$router.currentRoute.path)
    }
  }
}
