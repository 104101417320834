import { render, staticRenderFns } from "./the-main-nav.vue?vue&type=template&id=18be6d97&lang=pug&"
import script from "./the-main-nav.vue?vue&type=script&lang=js&"
export * from "./the-main-nav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNavItem: require('/co2unt/services/customer-app/components/app/app__nav-item/app__nav-item.vue').default,Footer: require('/co2unt/services/customer-app/components/footer/footer.vue').default})
