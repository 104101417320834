import { Customer } from '~/types'

interface CustomerState {
  customer: Customer
}

export const state = () => ({
  customer: {} as Customer
})

export const mutations = {
  mutateCustomer(state: CustomerState, data: Customer) {
    state.customer = data
  }
}

export const actions = {
  initCustomer({ dispatch, rootGetters }: any) {
    const isLoggedIn = rootGetters['store__app__user/isLoggedIn']
    if (isLoggedIn) {
      dispatch('store__api__customer/getCustomer', null, { root: true })
    }
  }
}

export const getters = {
  getCustomer(state: CustomerState) {
    return state.customer
  },
  isCustomerDefined(state: CustomerState) {
    return state.customer?.name !== undefined
  },
  getFirstname(state: CustomerState, _getters: any, _whatsThat: any, globalGetters: any) {
    const firstname =
      state?.customer?.address_id?.firstname || globalGetters['store__app__order-create/getInputValue']('firstname')

    return firstname
  },
  getLastname(state: CustomerState, _getters: any, _whatsThat: any, globalGetters: any) {
    const lastname =
      state?.customer?.address_id?.lastname || globalGetters['store__app__order-create/getInputValue']('lastname')
    return lastname
  },
  isBusinessCustomer(state: CustomerState) {
    return state.customer.customer_type === 'business'
  }
}
