export default async (_context, _locale) => {
  return await Promise.resolve({
    common: {
      backToPreviousStep: 'Zurück zum vorherigen Schritt',
      errors: {
        genericErrorMessage: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
        validation: {
          notEqual: 'Die Werte für {attribute1} und {attribute2} stimmen nicht überein.'
        }
      },
      flashMessages: {
        alreadySignedIn: 'Du bist bereits eingeloggt.',
        sessionExpired: 'Deine Session ist abgelaufen und du wurdest ausgeloggt.',
        pageNotAvailable: 'Die angefragte Seite ist derzeit leider nicht erreichbar.'
      },
      menu: {
        ariaLabel: 'Hauptnavigation',
        close: 'Menü schließen',
        title: 'Menü',
        privateCustomer: {
          headline: 'You CO₂unt',
          co2Calculator: 'CO₂-Rechner',
          myCo2unt: 'Mein CO₂unt'
        },
        businessCustomer: {
          headline: 'CO₂unt for Business',
          myCo2unt: 'Mein CO₂unt',
          manualCompensate: 'Manuelle Kompensation'
        }
      },
      notProvided: 'nicht angegeben',
      price: '{price} €',
      form: {
        select: {
          resetOptionTitle: 'Bitte auswählen:'
        }
      }
    },

    appStatus: {
      error: {
        headline: 'Ein Fehler ist aufgetreten',
        buttonCloseText: 'Fehler schließen'
      },
      info: {
        headline: 'Information',
        buttonCloseText: 'Hinweis schließen'
      },
      warning: {
        headline: 'Warnhinweis',
        buttonCloseText: 'Hinweis schließen'
      },
      success: {
        headline: 'Erfolgsnachricht',
        buttonCloseText: 'Hinweis schließen'
      }
    },

    footer: {
      ariaLabelNav: 'Fußnavigation',
      ariaLabelNavSocial: 'Navigaion für soziale Netzwerke',
      hintFollowUs: 'Folgen Sie uns:',
      dataPrivacySettings: 'Datenschutzeinstellungen'
    },

    pages: {
      co2Calculator: {
        ariaDescription: 'CO₂-Rechner-Navigation',
        headline: 'CO₂-Ausgleich',
        calculateResult: 'Ergebnis berechnen',
        calculateFootprintStep: 'Eingabe speichern und weiter'
      },
      error: {
        404: {
          h1: 'Die angegebene Seite konnte nicht gefunden werden!'
        },
        default: {
          h1: 'Es ist ein Fehler aufgetreten! Das Entwicklungsteam wurde bereits benachrichtigt.'
        },
        linkText: 'Zurück zur Startseite'
      },
      login: {
        headline: 'Anmeldung'
      },
      registration: {
        headline: 'Registrierung',
        button: 'Registrieren',
        messages: {
          mailSent: 'Wir haben dir eine E-Mail an deine E-Mail-Adresse geschickt: {email}.',
          registrationFailedEmailAlreadyTaken: 'Die E-Mail-Adresse {email} ist bereits vergeben.',
          registrationFailedGeneric:
            'Deine Registrierung ist fehlgeschlagen. Bitte versuche es später erneut oder wende dich an den Support.'
        }
      },
      registrationConfirmation: {
        headline: 'Registrierung abschließen',
        buttons: 'Registrierung abschließen',
        messages: {
          success: 'Dein Benutzerkonto wurde eingerichet. Du kannst dich nun einloggen.',
          registrationConfirmationFailedForMissingToken:
            'Die Bestätigung deines Kontos kann nur mit einem gültigen Token erfolgen. Der aufgerufene Link enthält allerdings keinen Token.',
          registrationConfirmationFailedGeneric:
            'Die Bestätigung deines Kontos ist fehlgeschlagen. Bitte versuche es später erneut oder wende dich an den Support.',
          registrationConfirmationFailedForNotInvitedEmailAddress:
            'Die Bestätigung deines Kontos ist fehlgeschlagen, da der Token abgelaufen ist oder bereits eingelöst wurde.'
        }
      },
      projectSelection: {
        singleCompensation: {
          privateCustomer: {
            headline: 'Werde klimaneutral und gleiche deinen CO₂-Fußabdruck über ein Klimaschutzprojekt aus',
            compensationMessage: 'Auszugleichende CO₂-Menge: {compensationAmountWithUnit}'
          }
        },
        footprintCompensation: {
          privateCustomer: {
            headline: 'Werde klimaneutral und gleiche deinen CO₂-Fußabdruck über ein Klimaschutzprojekt aus',
            compensationMessage:
              'Auszugleichende CO₂-Menge:  {compensationAmountWithUnit} (im Vergleich der deutsche Durchschnittsbürger: 55,77 t)'
          }
        }
      },
      orderComplete: {
        privateCustomer: {
          buttons: {
            register: 'Registrieren'
          },
          content: {
            additionalText:
              '<p>Dein persönliches Ausgleichszertifikat und den Zahlungsbeleg haben wir dir per E-Mail zugesendet.<br/>' +
              'Dein Klimaschutzbeitrag ist bei den Finanzämtern als Spende abzugsfähig.</p>' +
              '<p>Über die Tracking-URL {certificateUrl} ist dein CO₂-Ausgleich jederzeit transparent nachvollziehbar.<br/>' +
              'Du möchtest, dass auch andere einen Beitrag zum Klimaschutz leisten?<br/>' +
              'Dann teile diesen Link gern mit deinen Freunden und deiner Familie.</p>',
            headline: 'Vielen Dank!',
            thanksText: 'Wir bedanken uns für deinen Beitrag zum Klimaschutz.'
          },
          links: {
            backToHome: 'Zurück zur Startseite'
          }
        },
        businessCustomer: {
          content: {
            additionalText:
              '<p>Ihr bestelltes Ausgleichszertifikat und den Zahlungsbeleg haben wir ihnen per E-Mail zugesendet.<br/>' +
              'Ihr Klimaschutzbeitrag ist bei den Finanzämtern als Spende abzugsfähig.</p>' +
              '<p>Über die Tracking-URL {certificateUrl} ist ihr CO₂-Ausgleich jederzeit transparent nachvollziehbar.<br/>' +
              'Sie möchten, dass auch andere einen Beitrag zum Klimaschutz leisten?<br/>' +
              'Dann teilen Sie diesen Link gern mit ihren Geschäftspartnern.</p>',
            headline: 'Vielen Dank!',
            thanksText: 'Wir bedanken uns für Ihren Beitrag zum Klimaschutz.'
          },
          links: {
            backToHome: 'Zurück zur Startseite'
          }
        }
      },
      orderCreate: {
        headline: 'Deine Spende für das Klima',
        intro:
          'Du erhältst ein persönliches Ausgleichszertifikat und eine Spendenbescheinigung.<br/>Dein Klimaschutzbeitrag ist bei den Finanzämtern als Spende abzugsfähig.',
        labels: {
          price: 'Dein Beitrag zum Klimaschutz'
        },
        values: {
          compensationAmount: '{compensationAmountWithUnit} CO₂-Ausgleich'
        },
        buttons: {
          paypal: 'PayPal',
          creditCard: 'Kreditkarte'
        },
        messages: {
          manualOrderCreated: 'Ihr Auftrag wurde erstellt!',
          sendOrderFailedPrefix: 'Der Auftrag ist ungültig:',
          sendOrderFailedGeneric:
            'Dein Auftrag konnte nicht abgeschickt werden. Bitte versuche es später erneut oder wende dich an den Support.',
          paypalPaymentCancelled: 'Der Auftrag konnte nicht mit Paypal bezahlt werden. Bitte versuche es erneut.',
          paypalPaymentErrored:
            'Leider gab es ein Problem bei der Bezahlung mit Paypal. Bitte versuche es erneut oder wende dich an den Support.'
        }
      },
      orderCreateWithoutPayment: {
        submit: 'Kompensation buchen',
        headline: 'Emissionen ausgleichen',
        intro:
          'Unterstützen Sie unsere zertifizierten Klimaschutzprojekte durch den Ausgleich unvermeidlicher Emissionen.'
      },
      orderShow: {
        headline: 'Zertifikat',
        hintOrderNotFound: 'Das Zertifikat konnte nicht gefunden werden.'
      },
      orderOverview: {
        headline: 'Meine Aufträge',
        createCompensation: 'Neuen Auftrag anlegen',
        table: {
          head: {
            certificateNumber: 'Zertifikatnummer',
            compensationObject: 'CO₂-Ausgleich',
            projectName: 'Projektname',
            date: 'Datum',
            co2Quantity: 'CO₂-Menge',
            amount: 'Betrag',
            options: 'Optionen'
          }
        }
      }
    },

    components: {
      cardDoubleSided: {
        labels: {
          compensationAmount: 'Ausgeglichene CO₂-Menge:',
          price: 'Beitrag zum Klimaschutz:'
        },
        values: {
          compensationAmount: '{compensationAmountWithUnit}'
        },
        buttons: {
          chooseProject: 'Projekt wählen',
          moreInformation: 'Mehr erfahren',
          order: 'Jetzt ausgleichen'
        }
      },
      cardLogin: {
        headline: 'Login',
        button: 'Anmelden',
        messages: {
          success: 'Du wurdest erfolgreich am System angemeldet.',
          loginFailedGeneric:
            'Deine Anmeldung ist fehlgeschlagen. Bitte versuche es später erneut oder wende dich an den Support.',
          loginFailedUserLocked: 'Dein Benutzerkonto ist gesperrt.',
          loginFailedInvalidCredentials: 'Die eingegebeben Zugangsdaten sind ungültig.'
        }
      },
      cardOrder: {
        compensationAmount: '{amount} CO₂e',
        compensated: 'kompensiert',
        labels: {
          compensationObject: 'CO₂-Ausgleich für',
          compensationSubject: 'Auftraggeber',
          dateOfCertificate: 'Ausstellungsdatum',
          supportedProject: 'Unterstütztes Projekt',
          trackingId: 'Tracking-ID'
        },
        buttons: {
          copyLink: 'Link kopieren',
          showLabel: 'Label aufrufen',
          downloadCertificatePdf: 'Urkunde aufrufen',
          downloadLabelPdf: 'Label als PDF herunterladen{downloadInformation}'
        }
      },
      cardRegistration: {
        businessCustomer: {
          headline: 'Gemeinsam klimaneutral',
          button: 'Kontakt',
          text: 'Wir unterstützen Sie gern CO₂-Emissionen zu berechnen, zu reduzieren und durch Klimaschutzprojekte auszugleichen, damit Ihre Produkte und Dienstleistungen klimaneutral werden.'
        },
        privateCustomer: {
          headline: 'Registrierung',
          button: 'Registrieren',
          text: 'Du bist neu bei CO₂unt?<br>Hier kannst du mit nur wenigen Klicks dein Benutzerkonto anlegen.'
        }
      },
      certificateCheck: {
        label: 'ID-Zertifikatstracker',
        searchTitle: 'ZertifikatID suchen',
        flashMessages: {
          noOrderWithGivenCertificateNumberFound: 'Zur eingegebenen Tracking-ID konnte kein Zertifikat gefunden werden.'
        }
      },
      heroStage: {
        compareLabel: {
          private: 'Für Mich',
          business: 'Für Unternehmen'
        }
      },
      label: {
        headline: 'Klimaschutzbeitrag'
      },
      orderCreateCustomer: {
        labels: {
          authTypeGuest: 'Deine Daten',
          authTypeLogin: 'Ich bin schon bei CO₂unt registriert'
        }
      },
      orderCreateOrderData: {
        labels: {
          isGiftCertificate: 'Ich möchte mein CO₂-Ausgleichszertifikat verschenken.',
          hasCustomTitle: 'Ich möchte einen Titel für mein CO₂-Ausgleichszertifikat eingeben.'
        }
      },
      orderCreateOrderDataWithoutPayment: {
        labels: {
          subject: 'Kundenname',
          project: 'Klimaschutzprojekt',
          orderName: 'Auftragsbezeichnung',
          co2AmountInKgUnit: 'kg CO₂',
          co2AmountInKgLabel: 'Menge CO₂e',
          pricePerTLabel: 'EUR je t CO₂e',
          pricePerTUnit: 'EUR',
          priceTotalLabel: 'EUR Gesamt',
          priceTotalUnit: 'EUR',
          choosenCo2Receiver: 'CO₂-Ausgleich für ... ?'
        }
      },
      orderLabelBox: {
        buttons: {
          copyLink: 'Link kopieren',
          downloadLabelPdf: 'Label als PDF herunterladen {downloadInformation}'
        },
        messages: {
          copyLinkToClipboardSuccess: 'Link wurde in die Zwischenablage kopiert',
          copyLinkToClipboardError: 'Link konnte nicht in die Zwischenablage kopiert werden.'
        },
        headline: 'Label'
      },
      sectionContentStriped: {
        buttons: {
          moreInformation: 'Mehr erfahren'
        }
      },
      tableRowNav: {
        optionLabel: 'Optionen',
        optionEdit: 'Bearbeiten',
        optionOpenCertificate: 'Zertifikat aufrufen',
        optionOpenLabel: 'Label aufrufen',
        optionOpenDocument: 'Urkunde aufrufen',
        optionOrderCancel: 'Auftrag stornieren',
        optionOptionClose: 'option schließen'
      },
      theHeader: {
        co2Calculator: 'CO₂-Rechner',
        flashMessages: {
          signOutSuccess: 'Du wurdest erfolgreich vom System abgemeldet.'
        },
        login: 'Anmeldung',
        isLoggedIn: {
          welcomeTitle: 'Hallo, {name}',
          title: 'Mein CO₂unt',
          orders: 'Kompensationsaufträge',
          signOut: 'Abmelden',
          businessCustomer: {
            createCompensation: 'Auftrag anlegen'
          }
        }
      },
      theHeroStageComparison: {
        businessCustomer: {
          callToActionLabel: 'Mehr Erfahren'
        }
      },
      theMainNav: {
        ariaLabel: 'Erweiterte Hauptnavigation'
      },
      theSplashscreen: {
        title: 'Zeit zu handeln'
      },
      pagination: {
        paginationItem: {
          startTitle: 'zum Anfang',
          endTitle: 'zum Ende',
          backTitle: 'zurück',
          nextTitle: 'weiter',
          activeTitle: 'Seite {currentPageNumber} von {maxPageNumber}'
        }
      }
    },

    mixins: {
      compensationObjectTypesMixin: {
        objectTypes: {
          company: 'Unternehmen',
          none: 'Nicht angegeben',
          product: 'Produkt',
          print_product: 'Druckprodukt',
          public_award_procedure: 'Öffentliche Vergabe',
          website: 'Webseite'
        }
      }
    },

    routes: {
      co2Calculator: {
        index: 'kalkulator'
      }
    },

    attributes: {
      email: 'E-Mail-Adresse',
      name: 'Name',
      firstname: 'Vorname',
      lastname: 'Nachname',
      street: 'Straße',
      housenumber: 'Nr.',
      zipcode: 'PLZ',
      city: 'Ort',
      password: 'Passwort',
      passwordConfirmation: 'Passwortbesätigung',
      compensationSubjectTitle: 'Name des Beschenkten',
      customTitle: 'Titel des CO₂-Ausgleichszertifikats',
      requiredField: '* Pflichtfelder'
    }
  })
}
