import { HttpLink } from '@apollo/client/core'

import fetch from 'cross-fetch'

import { cache } from './'
import { rootTypeDefs } from './types'
import { extensionTypeDefs } from './types'
import { localTypeDefs } from './types'

export default args => {
  const uri = args.$config.graphqlUriServerFallback || args.$config.graphqlUri
  const httpLink = new HttpLink({ uri, fetch })
  return {
    link: httpLink,
    cache,
    typeDefs: [rootTypeDefs, extensionTypeDefs, localTypeDefs],
    defaultHttpLink: false
  }
}
