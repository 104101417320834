export var ComparisonSliderMixin = {
  data() {
    return {
      showCallToAction: undefined
    }
  },
  created() {
    this.setShowCallToActionAccordingToPath(this.$router.currentRoute.path)
  },
  watch: {
    $route(to) {
      this.setShowCallToActionAccordingToPath(to.path)
    }
  },
  methods: {
    setShowCallToActionAccordingToPath(path) {
      this.showCallToAction = this.isBusinessCustomerRootPath(path) || this.isPrivateCustomerRootPath(path)
    }
  }
}
