export default {
  orderOverviewPath: (_store, getters) => (path, thisContext) => {
    const location = thisContext.localeLocation({
      name: 'customerTypeSlug-orderOverview',
      params: {
        customerTypeSlug: getters.customerTypeSlugForPathOrDefault(path)
      }
    })
    const resolve = thisContext.$router.resolve(location)
    return resolve.route.path
  }
}
