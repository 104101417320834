import { pagesForRoutesQuery, calculatorDataCompleteWithMultipleLanguageTranslations } from '~/plugins/graphql'

export const state = () => ({
  pages: {},
  calculatorData: {}
})

export const mutations = {
  mutatePages(state, data) {
    // TODO: Use Vue.set to trigger reactive data changes.
    state.pages = data
  },
  mutateCalculatorData(state, data) {
    state.calculatorData = data
  }
}

export const actions = {
  async requestCmsRoutes(vuexContext) {
    await this.app.apolloProvider.defaultClient.query({ query: pagesForRoutesQuery }).then(response => {
      vuexContext.commit('mutatePages', { ...response.data })
      this.app.store.dispatch('store__app__routes/generateCmsRoutes', response.data)
    })
  },
  async requestCalculatorRoutes(vuexContext) {
    await this.app.apolloProvider.defaultClient
      .query({
        query: calculatorDataCompleteWithMultipleLanguageTranslations()
      })
      .then(response => {
        vuexContext.commit('mutateCalculatorData', { ...response.data })
        this.app.store.dispatch('store__app__routes/generateCalculatorRoutes', response.data)
      })
  }
}

export const getters = {
  getPages(state) {
    return state.pages
  },
  getCalculatorData(state) {
    return state.calculatorData
  }
}
