export default {
  getCmsRoutes: state => state.generatedCmsRoutesData,

  getCmsRouteFromPageId: (_state, getters) => pageId =>
    getters.getCmsRoutes.find(cmsRoute => cmsRoute.props.routeConfig.id === pageId),

  getRootRoutes: (_state, getters) => getters.getCmsRoutes.filter(route => route.props.routeConfig.isRoot),

  getLocalizedRoutes: () => (routes, languageCode) =>
    routes.filter(route => route.path.startsWith(`/${languageCode}/`)),

  getLocalizedCmsRoutes: (_state, getters) => languageCode =>
    getters.getLocalizedRoutes(getters.getCmsRoutes, languageCode),

  getLocalizedRootRoutes: (_state, getters) => languageCode =>
    getters.getLocalizedRoutes(getters.getRootRoutes, languageCode),

  getCmsPageIdFromRoute: _state => routeObject => {
    const pageId = routeObject?.props?.routeConfig?.id
    if (pageId) return Number(pageId)
  }
}
