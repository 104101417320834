import { authRefreshMutation } from '~/plugins/graphql'

export const state = () => ({
  pending: false,
  success: undefined
})

export const mutations = {
  mutatePending(state, data) {
    state.pending = data.flag
  },
  mutateSuccess(state, data) {
    state.success = data.flag
  }
}

export const actions = {
  async refreshSession(vuexContext, refreshToken) {
    await this.app.apolloProvider.defaultClient
      .mutate({
        mutation: authRefreshMutation,
        variables: { refresh_token: refreshToken }
      })
      .then(async response => {
        const newAccessToken = response.data.auth_refresh.access_token
        const newRefreshToken = response.data.auth_refresh.refresh_token
        const newExpires = new Date().getTime() + Number.parseInt(response.data.auth_refresh.expires)

        await this.app.store.dispatch('store__app__user/refreshUser', {
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
          expirationDate: newExpires
        })

        vuexContext.commit('mutateSuccess', { flag: true })
      })
      .catch(error => {
        let errorMessage

        vuexContext.commit('mutateSuccess', { flag: false })

        switch (error.message) {
          case '"refresh_token" is required in either the JSON payload or Cookie':
            errorMessage = 'refreshSessionFailedEmptyRefreshToken'
            break
          case '"String cannot represent a non string value: undefined"':
            errorMessage = 'refreshTokenFailedUndefinedRefreshToken'
            break
          default:
            errorMessage = 'refreshTokenFailedGeneric'
        }

        console.error(
          '[store__api__refresh__session]',
          ['refreshSession'],
          ' error: ',
          error,
          ' errorMessage: ',
          errorMessage
        )
        this.$sentry.captureException(error)
      })

    vuexContext.commit('mutatePending', { flag: false })
  }
}

export const getters = {
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}
