import { PRIVATE_CUSTOMER_STRING } from '~/constants'
import { languageCodeByPath } from '~/helpers/translation-helper'

export default {
  isPrivateCustomerRoute: () => routeObject => routeObject.props.routeConfig.customerType == PRIVATE_CUSTOMER_STRING,

  privateCustomerRootPath: (_state, getters) => languageCode =>
    getters.getLocalizedRootRoutes(languageCode).find(route => getters.isPrivateCustomerRoute(route)).path,

  isPrivateCustomerRootRoute: (_state, getters) => route =>
    route.path === getters.privateCustomerRootPath(languageCodeByPath(route.path))
}
