
import DropdownNavigation from '~/components/the-header/dropdown-navigation/dropdown-navigation'
import { isPrivateCustomerPath } from '~/helpers/routes-helper'
import { RoutesMixin, TranslationMixin } from '~/mixins'

export default {
  name: 'TheHeader',
  mixins: [RoutesMixin, TranslationMixin],
  components: { DropdownNavigation },
  data() {
    return {
      calculatorRootPath: undefined
    }
  },
  computed: {
    showCalculatorButton() {
      return isPrivateCustomerPath(this.$router.currentRoute.path, this.$store)
    },
    isLoggedIn() {
      return this.$store.getters['store__app__user/isLoggedIn']
    },
    orderCreateWithoutPaymentPath() {
      return this.$store.getters['store__app__routes/orderCreateWithoutPaymentPath'](this)
    },
    user() {
      return this.$store.getters['store__app__user/getUser']
    },
    userAlias() {
      if (this.usersMe) {
        if (this.usersMe?.first_name && this.usersMe?.last_name) {
          return `${this.usersMe.first_name[0]}. ${this.usersMe.last_name}`
        } else {
          return this.user?.email
        }
      }
    },
    usersMe() {
      return this.$store.getters['store__app__user/getUsersMe']
    }
  },
  created() {
    this.calculatorRootPath = this.calculatorRootPathAccordingToPath(this.$router.currentRoute.path)
  },
  watch: {
    $route(to, _from) {
      this.calculatorRootPath = this.calculatorRootPathAccordingToPath(to.path)
    }
  },
  methods: {
    removeFocus(event) {
      const parentElement = event.target.closest('.nav-header__dropdown')
      const hideTimeout = 50
      const blurTimeout = 250

      if (parentElement) {
        window.setTimeout(function () {
          event.target.blur()

          // to deactive css-over style and hide dropdown
          parentElement.classList.add('nav-header__dropdown--js-hide')

          window.setTimeout(function () {
            parentElement.classList.remove('nav-header__dropdown--js-hide')
          }, hideTimeout)
        }, blurTimeout)
      }
    },
    openNavMain() {
      this.$store.dispatch('store__app/setShowHeaderNavMain', { flag: true })
    }
  }
}
