import { extractFromCookie, removeKeysFromCookie, setUserCookies } from '~/helpers/cookie-helper.js'

export const state = () => ({
  user: {
    accessToken: undefined,
    email: undefined,
    refreshToken: undefined
  },
  usersMe: {},
  role: {}
})

export const mutations = {
  mutateLogoutUser(state, _data) {
    state.user = {
      email: undefined,
      accessToken: undefined,
      refreshToken: undefined
    }
    state.usersMe = {}
  },
  mutateUser(state, data) {
    state.user.accessToken = data.accessToken
    state.user.refreshToken = data.refreshToken
    state.user.expires = data.expires
    if (data.email) {
      state.user.email = data.email
    }
  },
  mutateUsersMe(state, data) {
    state.usersMe = data
  },
  mutateRoleForUser(state, data) {
    state.role = data
  }
}

export const actions = {
  async initAuth(vuexContext, req) {
    const accessToken = extractFromCookie(req, 'accessToken')
    const expirationDate = Number.parseInt(extractFromCookie(req, 'expirationDate'))
    const email = extractFromCookie(req, 'email')
    console.debug('user signed in:', email)
    const refreshToken = extractFromCookie(req, 'refreshToken')

    if (!accessToken) {
      console.log('[StoreAppUser]', '[initAuth]:', `No accessToken given, perform logoutUser`)
      await vuexContext.dispatch('logoutUser', refreshToken)
      return
    }

    if (new Date().getTime() > expirationDate) {
      console.log('[StoreAppUser]', '[initAuth]:', `AccessToken timed out, perform expireSession`)
      await vuexContext.dispatch('expireSession')
      return
    }

    await vuexContext.commit('mutateUser', {
      accessToken: accessToken,
      email: email,
      refreshToken: refreshToken
    })

    await apolloLogin(this, accessToken)
    this.app.store.dispatch('store__app__customer/initCustomer')
  },
  async expireSession(vuexContext) {
    await vuexContext.dispatch('logoutUser')

    // Unfortunately here is the only place where we can trigger this notification.
    // Prefer to do this in a component if possible.
    this.app.store.dispatch('store__app/setAppStatusWarningMessage', {
      message: this.app.store.$i18n.t('common.flashMessages.sessionExpired')
    })
  },
  async logoutUser(vuexContext) {
    const accessToken = extractFromCookie(null, 'accessToken')
    if (accessToken) {
      await this.app.store.dispatch('store__api__logout/logoutUser')
    }

    await this.app.$apolloHelpers.onLogout()

    vuexContext.commit('mutateLogoutUser', {})
    removeKeysFromCookie(['accessToken', 'expirationDate', 'email', 'refreshToken'])
  },
  loginUser(vuexContext, data) {
    return new Promise((resolve, reject) => {
      this.app.store
        .dispatch('store__api__login/authenticate', data)
        .then(async authData => {
          const storeData = { ...authData }
          storeData.email = data.email
          vuexContext.commit('mutateUser', storeData)
          setUserCookies(storeData)

          await apolloLogin(this, storeData.accessToken)

          const validationResult = await isValidRole(vuexContext, this)
          if (!validationResult) {
            const errorMessageKey = 'loginFailedInvalidCredentials'
            throw errorMessageKey
          }

          setCustomerInStore(this)
          resolve(true)
        })
        .catch(async errorMessageKey => {
          await this.app.store.dispatch('store__app__user/logoutUser')
          reject(errorMessageKey)
        })
    })
  },
  async refreshSessionToken(vuexContext, req) {
    const expirationDate = Number.parseInt(extractFromCookie(req, 'expirationDate'))
    const refreshToken = extractFromCookie(req, 'refreshToken')

    if (!refreshToken) return

    if (new Date().getTime() > expirationDate) {
      console.debug('[StoreAppUser]', '[refreshSessionToken]:', `AccessToken timed out, perform expireSession`)
      await vuexContext.dispatch('expireSession')
      return
    }

    await refreshSession(this, refreshToken, expirationDate)
  },
  refreshUser(vuexContext, data) {
    vuexContext.commit('mutateUser', data)
    setUserCookies(data)
  }
}

export const getters = {
  getUser(state) {
    return state.user
  },
  getUsersMe(state) {
    return state.usersMe
  },
  isLoggedIn(state) {
    return `${state.user.accessToken}` != 'undefined'
  }
}

// private functions

const apolloLogin = async (thisBinding, accessToken) => {
  if (!thisBinding.$apolloHelpers.getToken()) {
    await thisBinding.$apolloHelpers.onLogin(accessToken)
    await thisBinding.app.store.dispatch('store__api__users__me/getUsersMe')
  }
}

const isValidRole = async (vuexContext, thisBinding) => {
  const allowedRole = await thisBinding.app.store.getters['store__app/getRoleCustomer']
  await thisBinding.app.store.dispatch('storeApiRoleForUser/getRoleForUser')
  const givenRole = vuexContext.state.role
  console.log(allowedRole.name)
  console.log(givenRole.name)
  return allowedRole.name == givenRole.name
}

const setCustomerInStore = thisBinding => {
  thisBinding.app.store.dispatch('store__app__customer/initCustomer')
}

const refreshSession = async (thisBinding, refreshToken, expirationDate) => {
  const REFRESH_TOKEN_THRESHHOLD_MINUTES = 5
  const SUBTRACTED_MINUTES_FROM_EXPIRATION_DATE = 10
  const MILLISECONDS_IN_MINUTES = 60000

  const calculatedMinutesInMilliseconds = REFRESH_TOKEN_THRESHHOLD_MINUTES * MILLISECONDS_IN_MINUTES
  const addedMinutesToCurrentDateTime = new Date().getTime() + calculatedMinutesInMilliseconds

  const subtractedMinutesFromExpirationDate = SUBTRACTED_MINUTES_FROM_EXPIRATION_DATE * MILLISECONDS_IN_MINUTES
  const expirationDateForRefresh = expirationDate - subtractedMinutesFromExpirationDate

  if (addedMinutesToCurrentDateTime < expirationDateForRefresh) {
    thisBinding.app.store.dispatch('store__api__refresh__session/refreshSession', refreshToken)
  }
}
