export default {
  isRouteAllowedForMobile: (_state, getters) => (route, context) => {
    // refs #124339, private customer routes are disabled for every device.
    // If we want to display the private routes again, just delete the early return!
    return true
    const isCustomerRootRoute = getters.isPrivateCustomerRootRoute(route) || getters.isBusinessCustomerRootRoute(route)

    const isSessionRoute = getters.isLoginRoute(route, context) || getters.isRegistrationRoute(route, context)

    return !isCustomerRootRoute && !isSessionRoute
  }
}
