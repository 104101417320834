import { registerCustomerMutation } from '~/plugins/graphql'

export const state = () => ({
  pending: false,
  success: undefined
})

export const mutations = {
  mutatePending(state, data) {
    state.pending = data.flag
  },
  mutateSuccess(state, data) {
    state.success = data.flag
  }
}

export const actions = {
  async register(vuexContext) {
    vuexContext.commit('mutatePending', { flag: true })
    vuexContext.commit('mutateSuccess', { success: undefined })

    const base_url = this.app.context.$config.baseUri
    const location = this.app.store.getters['store__app__routes/registrationConfirmationPath'](
      this.$router.currentRoute.path,
      this
    )
    const resolve = this.$router.resolve(location)
    const url = base_url.concat(resolve.route.path)
    const address = this.app.store.getters['store__app__registration/getAddress']
    const customerName = `${address.firstname} ${address.lastname}`

    await this.app.apolloProvider.defaultClient
      .mutate({
        mutation: registerCustomerMutation,
        variables: {
          address: address,
          customerName: customerName,
          userRegistrationUrl: url
        }
      })
      .then(_response => {
        vuexContext.commit('mutateSuccess', { flag: true })

        this.app.store.dispatch('store__app/setAppStatusSuccessMessage', {
          message: this.app.i18n.t('pages.registration.messages.mailSent', {
            email: address.email
          })
        })
      })
      .catch(error => {
        let errorMessage

        if (error.message === 'GraphQL error: Field "email" has to be unique.') {
          errorMessage = this.app.i18n.t('pages.registration.messages.registrationFailedEmailAlreadyTaken', {
            email: address.email
          })
        } else {
          errorMessage = this.app.i18n.t('pages.registration.messages.registrationFailedGeneric')
          this.$sentry.captureException(error)
        }

        this.app.store.dispatch('store__app/setAppStatusErrorMessage', {
          message: errorMessage
        })

        vuexContext.commit('mutateSuccess', { flag: false })
      })

    vuexContext.commit('mutatePending', { flag: false })
  }
}

export const getters = {
  isPending(state) {
    return state.pending
  },
  isSuccess(state) {
    return state.success
  }
}
