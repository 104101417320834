import GoogleTagManagerRenderScriptDecision from '~/plugins/googleTagManager/renderScriptDecision'

export default args => {
  if (!shouldRenderGtmScript(args)) return function () {}

  gtmScript(args)
  userCentricsScript(args)
}

function shouldRenderGtmScript(args) {
  return GoogleTagManagerRenderScriptDecision(args.$config.containerMode)
}

function gtmScript(args) {
  const gtmId = args.$config.gtmId

  return (function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', gtmId)
}

function userCentricsScript(args) {
  const userCentricsId = args.$config.userCentricsId
  if (!userCentricsId) return

  insertUserCentricsScriptToHead(userCentricsId)
}

function insertUserCentricsScriptToHead(userCentricsId) {
  const script = document.createElement('script')
  script.async = true
  script.src = `https://app.usercentrics.eu/browser-ui/latest/loader.js`
  script.id = 'usercentrics-cmp'
  script.dataset.settingsId = userCentricsId

  document.head.appendChild(script)
}
