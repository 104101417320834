
const logClass = '[AppVideo]'

export default {
  name: 'AppVideo',
  props: {
    videoFileFromDirectus: {
      type: String
    },
    autoplayVideo: {
      type: Boolean,
      default: true
    },
    classProp: {
      type: String
    }
  },
  methods: {
    onClickTogglePlay() {
      if (this.$refs.currentVideo.paused) {
        this.$refs.currentVideo.play()
        this.showPauseStyle = false
      } else {
        this.$refs.currentVideo.pause()
        this.showPauseStyle = true
      }
    }
  },
  computed: {
    videoSource() {
      return `${this.$config.backofficeUrl}/assets/${this.videoFileFromDirectus}`
    },
    showPauseStyle() {
      if (!this.autoplayVideo) return true

      return false
    }
  }
}
