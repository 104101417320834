import gql from 'graphql-tag'

export const CustomerDataQuery = gql`
  query customerDataQuery {
    customers {
      id
      name
      customer_type
      address_id {
        firstname
        lastname
        street
        housenumber
        zipcode
        city
      }
    }
  }
`
