import { businessCustomerRootPath, isBusinessCustomerPath } from '~/helpers/routes-helper'
import { languageCodeByPath } from '~/helpers/translation-helper'

export default async function (context) {
  const path = context.route.path
  const languageCode = languageCodeByPath(path)
  const store = context.store

  await setIsBusinessCustomerPath(path, store)

  if (!isBusinessCustomerPathFromStore(store) && !isPrivateCustomerRegistrationPath(path) && !isCertificatePath(path)) {
    context.redirect(businessCustomerRootPath(languageCode, store))
  }
}

// TODO: refactor because it is used in multiple places
function isBusinessCustomerPathFromStore(store) {
  return store.getters['store__app/isBusinessCustomerPath']
}

// TODO: refactor because it is used in multiple places
function setIsBusinessCustomerPath(path, store) {
  store.dispatch('store__app/setIsBusinessCustomerPath', isBusinessCustomerPath(path, store))
}

function isPrivateCustomerRegistrationPath(path) {
  return (
    path.indexOf('/you/anmelden') !== -1 ||
    path.indexOf('/you/datenschutz') !== -1 ||
    path.indexOf('/you/registrierung') !== -1 ||
    path.indexOf('/you/registrierung-bestaetigen') !== -1
  )
}

function isCertificatePath(path) {
  return path.indexOf('/zertifikate') !== -1
}
