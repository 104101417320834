import { languageCodeByPath, localeByLanguageCode, translationByPath } from '~/helpers/translation-helper.js'

export var TranslationMixin = {
  computed: {
    languageCode() {
      return languageCodeByPath(this.$router.currentRoute.path)
    },
    locale() {
      return localeByLanguageCode(this.languageCode)
    }
  },
  methods: {
    getTranslation(translations, path = this.$router.currentRoute.path) {
      return translationByPath(path, translations)
    }
  }
}
