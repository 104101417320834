
import { TranslationMixin } from '~/mixins'

export default {
  name: 'DropdownNavigationBusinessCustomer',
  mixins: [TranslationMixin],
  computed: {
    orderCreateWithoutPaymentPath() {
      return this.$store.getters['store__app__routes/orderCreateWithoutPaymentPath'](this)
    }
  }
}
