import gql from 'graphql-tag'

import { translationsFilter } from '../filters'

export function calculatorDataComplete(translationFilterEnabled) {
  if (translationFilterEnabled === undefined) translationFilterEnabled = true
  const queryParams = '$locale: String!'
  return gql`
    ${
      translationFilterEnabled
        ? `query calculatorDataComplete (${queryParams}) {`
        : 'query calculatorDataCompleteWithMultipleLanguageTranslations {'
    }
      event_types(limit: -1, sort: "sort") {
        id
        status
        video_mobile {
          id
          filename_disk
          title
        }
        video {
          id
          filename_disk
          title
        }
        ${translationsFilter(translationFilterEnabled)} {
          name
          question
          languages_code {
            code
          }
        }
        parent_event_type {
          id
        }
        calculator_input {
          id
          unit
          decimal_places
          factor
          event_type {
            id
          }
          video_mobile {
            id
            filename_disk
            title
          }
          video {
            id
            filename_disk
            title
          }
          ${translationsFilter(translationFilterEnabled)} {
            label
            question
            project_selection_headline
            languages_code {
              code
            }
          }
        }
        sub_event_types(limit: -1) {
          id
        }
        image {
          id
          filename_disk
          title
        }
        image_mobile {
          id
          filename_disk
          title
        }
      }
      calculator_input(limit: -1) {
        id
        unit
        decimal_places
        factor
        event_type {
          id
        }
        ${translationsFilter(translationFilterEnabled)} {
          label
          question
          project_selection_headline
          languages_code {
            code
          }
        }
      }
    }
  `
}

export function calculatorDataCompleteWithMultipleLanguageTranslations() {
  return calculatorDataComplete(false)
}
