import { businessCustomerRootPath, isBusinessCustomerPath, isCalculatorRoute } from '~/helpers/routes-helper'
import { languageCodeByPath } from '~/helpers/translation-helper'

export default async function (context) {
  const path = context.route.path
  const languageCode = languageCodeByPath(path)
  const store = context.store

  await setIsBusinessCustomerPath(path, store)

  if (isCalculatorRoute(path, languageCode, store) && isBusinessCustomerPathFromStore(store)) {
    context.redirect(businessCustomerRootPath(languageCode, store))
  } else {
  }
}

// TODO: refactor because it is used in multiple places
function isBusinessCustomerPathFromStore(store) {
  return store.getters['store__app/isBusinessCustomerPath']
}

// TODO: refactor because it is used in multiple places
function setIsBusinessCustomerPath(path, store) {
  store.dispatch('store__app/setIsBusinessCustomerPath', isBusinessCustomerPath(path, store))
}
