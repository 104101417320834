import { ConvertDataMixin } from '~/mixins/convert-data-mixin.js'

const MINIMUM_DONATION_AMOUNT = 8.0

export var PriceMixin = {
  mixins: [ConvertDataMixin],
  methods: {
    calculatePrice(compensationAmount, price_per_t) {
      const calculatedPrice = compensationAmount * this.convertStringToFloat(price_per_t)
      return calculatedPrice > MINIMUM_DONATION_AMOUNT ? calculatedPrice : MINIMUM_DONATION_AMOUNT
    }
  }
}
