
import { TranslationMixin } from '~/mixins'
import { orderQuery } from '~/plugins/graphql'

export default {
  name: 'FormCertificateCheck',
  mixins: [TranslationMixin],
  data() {
    return {
      trackingId: '',
      order: {},
      skipQuery: true
    }
  },
  watch: {
    order(newOrder, oldOrder) {
      if (newOrder?.tracking_id && newOrder?.tracking_id != oldOrder?.tracking_id) {
        this.goToOrderShow()
      }
    }
  },
  apollo: {
    order: {
      query: orderQuery,
      update(data) {
        const orders = data.orders
        if (orders[0]) {
          return orders[0]
        }

        this.renderOrderNotFoundFlashMessage()
        return {}
      },
      skip: true,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    goToOrderShow() {
      this.$router.push(this.$store.getters['store__app__routes/certificatePath'](this.trackingId, this))
    },
    renderOrderNotFoundFlashMessage() {
      this.$store.dispatch('store__app/setAppStatusWarningMessage', {
        message: this.$t('components.certificateCheck.flashMessages.noOrderWithGivenCertificateNumberFound')
      })
    },
    searchTrackingId() {
      if (this.trackingId) {
        this.$apollo.queries.order.setVariables({
          trackingId: this.trackingId,
          locale: this.locale
        })
        this.$apollo.queries.order.skip = false
      }
    }
  }
}
