import { RolesFilteredForCustomerRoleQuery } from '~/plugins/graphql'

const logClass = '[Store]'

export const actions = {
  async nuxtServerInit(_vuexContext, nuxtContext) {
    const logMethod = '[nuxtServerInit]'

    try {
      // Prevent Token expiration while nuxtServerInit:
      await this.app.store.dispatch('store__app__user/logoutUser')

      await this.app.apolloProvider.defaultClient.query({ query: RolesFilteredForCustomerRoleQuery }).then(response => {
        this.app.store.dispatch('store__app/setRoleCustomer', {
          ...response.data.roles[0]
        })
      })

      await this.app.store.dispatch('store__api__routes/requestCmsRoutes')
      await this.app.store.dispatch('store__api__routes/requestCalculatorRoutes')

      console.debug(logClass, logMethod, 'Performed nuxtServerInit.')
    } catch (error) {
      this.app.store.dispatch('store__app/setAppStatusErrorMessage', {
        message: error.message
      })
      nuxtContext.$sentry.captureException(error)
    }
  }
}
