import { BUSINESS_CUSTOMER_STRING } from '~/constants'
import { languageCodeByPath } from '~/helpers/translation-helper'

export default {
  isBusinessCustomerRoute: () => routeObject => routeObject.props.routeConfig.customerType == BUSINESS_CUSTOMER_STRING,

  businessCustomerRootPath: (_state, getters) => languageCode =>
    getters.getLocalizedRootRoutes(languageCode).find(route => getters.isBusinessCustomerRoute(route)).path,

  isBusinessCustomerRootRoute: (_state, getters) => route =>
    route.path === getters.businessCustomerRootPath(languageCodeByPath(route.path))
}
