import { languageCodeByPath } from '~/helpers/translation-helper'

export default function (context) {
  const path = context.route.path
  const languageCode = languageCodeByPath(path)

  if (languageCode !== 'de') {
    context.redirect({ name: `index___de` })
  }
}
