
import { isLanguageRootPath } from '~/helpers/routes-helper'
import { GoogleTagManagerIntegrationMixin, LayoutMixin, RoutesMixin } from '~/mixins'

export default {
  name: 'default',
  mixins: [GoogleTagManagerIntegrationMixin, LayoutMixin, RoutesMixin],
  data() {
    return {
      currentPageRootClass: undefined,
      showHeroStage: undefined
    }
  },
  watch: {
    $route(to) {
      this.setHeroStageForPath(to.path)
      this.setCurrentPageRootClass(to.path)
      this.hideScrollDownButtonForPath(to.path)
      this.addGtmScriptToBody()

      this.$store.dispatch('store__app/setPageHeadline', undefined)
    }
  },
  methods: {
    setCurrentPageRootClass(path) {
      this.currentPageRootClass = this.getCurrentPageRootCssClass(path)
    },
    setHeroStageForPath(path) {
      if (isLanguageRootPath(path)) {
        this.showHeroStage = true
        return
      }

      const route = this.$router.currentRoute

      this.showHeroStage =
        !!this.route(path)?.props?.routeConfig?.isRoot ||
        this.$store.getters['store__app__routes/isLoginRoute'](route, this) ||
        this.$store.getters['store__app__routes/isRegistrationRoute'](route, this) ||
        this.$store.getters['store__app__routes/isRegistrationConfirmationRoute'](route, this)
    },
    hideScrollDownButtonForPath(path) {
      if (!this.route(path)?.props?.routeConfig?.isRoot) {
        this.$store.dispatch('store__app/setShowTheButtonScrolldown', {
          flag: false
        })
      }
    }
  },
  created() {
    this.setHeroStageForPath(this.$router.currentRoute.path)
    this.setCurrentPageRootClass(this.$router.currentRoute.path)
  }
}
