
import { ComparisonSliderMixin, RoutesMixin, TranslationMixin } from '~/mixins'

export default {
  name: 'PrivateCustomerSection',
  mixins: [ComparisonSliderMixin, RoutesMixin, TranslationMixin],
  props: {
    item: {
      type: Object,
      required: true,
      translations: {
        type: Array,
        required: true
      }
    },
    showFirstContent: {
      type: Boolean,
      required: true
    }
  }
}
